import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';


import {
  getOperatorsGroupsTemplate,
  exportOperatorsGroups,
  importOperatorsGroups,
  getOperatorsGroups,
  resetFileUploadErrors,
} from '~/store/manager/actions';

const GroupsTab = ({
  classes,
  operatorsGroups,
  getOperatorsGroupsTemplate: getTemplateAction,
  exportOperatorsGroups: exportAction,
  importOperatorsGroups: importAction,
  getOperatorsGroups: getAll,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const [showDialogImport, setShowDialogImport] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!groupsLoaded) {
      fetchOperators();
      setGroupsLoaded(true);
    }
  }, [groupsLoaded, getAll]);

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
    }
  }, [fileUploadErrors]);

  const columns = [
    {
      field: 'name', title: translate('common:Name'),
    },
    {
      field: 'description', title: translate('common:Description'), width: '100%',
    },
    {
      field: 'active_name', title: 'STATUS',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        columns={columns}
        data={operatorsGroups}
      />
      <Footer />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
    </div>
  );
};

GroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  operatorsGroups: PropTypes.array,
  getOperatorsGroupsTemplate: PropTypes.func,
  exportOperatorsGroups: PropTypes.func,
  importOperatorsGroups: PropTypes.func,
  getOperatorsGroups: PropTypes.func,
};

GroupsTab.defaultProps = {
  operatorsGroups: [],
  getOperatorsGroupsTemplate: () => {},
  exportOperatorsGroups: () => {},
  importOperatorsGroups: () => {},
  getOperatorsGroups: () => {},
};

const mapStateToProps = state => ({
  operatorsGroups: state.manager.operatorsGroups,
});

const mapDispatchToProps = {
  getOperatorsGroupsTemplate,
  exportOperatorsGroups,
  importOperatorsGroups,
  getOperatorsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsTab));
