import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { FormControl, FormLabel, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentGroups, getEquipmentTypes } from '~/store/nref/actions';
import { getTruckIndicators, getLoaderIndicators } from '~/store/dispatch/actions';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import LoadersEquipmentList from './LoaderEquipmentList';
import TrucksEquipmentList from './TruckEquipmentList';
import usePlatformConfiguration from '~/services/platformConfiguration';

const EQUIPGROUP_BLANK_FILTERS = {
  equipmentTypeId: 0,
  equipmentGroupId: 0,
};

function useEquipmentListFilters(initFilters = EQUIPGROUP_BLANK_FILTERS) {
  const [filters, _setFilters] = useState(initFilters);

  const setFilters = (name, value) => _setFilters((fil) => {
    if (fil[name] === value) {
      return fil;
    }
    const multiChanges = {};
    if (name === 'equipmentTypeId') {
      multiChanges.equipmentGroupId = initFilters.equipmentGroupId;
    }
    return { ...fil, [name]: value, ...multiChanges };
  });

  return [filters, setFilters];
}


const mapper = {
  1: LoadersEquipmentList,
  2: TrucksEquipmentList,
  15: LoadersEquipmentList,
};

const DispatchEquipmentDetailList = withStyles(styles)(({ classes, setFilters, filters }) => {
  const { t: translate } = useTranslation();
  const { equipmentTypes, equipmentGroups, equipmentInfo } = useSelector((state) => {
    const { equipmentTypes: allTypes } = state.nref;
    const truck = allTypes.find(eType => eType.id === 2);
    const equipmentTypeOptions = [truck].filter(Boolean);

    const equipSet = new Set(equipmentTypeOptions.map(eType => eType.id));
    const equipmentGroupOptions = state.nref.equipmentsGroups
      .filter(eGroup => equipSet.has(eGroup.id_equipament));

    const equipInfo = state.nref.equipments
      .filter(equip => equipSet.has(equip.id_equip))
      .reduce((acc, equip) => ({ ...acc, [equip.id]: equip }), {});
    return {
      equipmentTypes: equipmentTypeOptions,
      equipmentGroups: equipmentGroupOptions,
      equipmentInfo: equipInfo,
    };
  });
  const EquipmentListComponent = (
    mapper[filters.equipmentTypeId] || (() => (<p>{translate('common:ChooseEquipmentType')}</p>))
  );

  return (
    <form className={classes.form}>
      <div className={classes.collapseTruckListForm}>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:EquipmentType')}
          </FormLabel>
          <PF2MSearchSelectOutlined
            type="text"
            onChange={event => setFilters('equipmentTypeId', event.target.value)}
            onBlur={setFilters}
            value={filters.equipmentTypeId}
            name="equipmentTypeId"
            clearedValue="0"
          >
            {[{ value: 0, label: translate('common:Select') }].concat(
              equipmentTypes
                .map(equipType => ({ label: equipType.name, value: equipType.id })),
            )}
          </PF2MSearchSelectOutlined>
        </FormControl>

        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:EquipmentGroup')}
          </FormLabel>
          <PF2MSearchSelectOutlined
            type="text"
            onChange={event => setFilters('equipmentGroupId', event.target.value)}
            onBlur={setFilters}
            value={filters.equipmentGroupId}
            clearedValue="0"
            name="equipmentGroupId"
          >
            {[{ value: 0, label: translate('common:Select') }].concat(
              equipmentGroups
                .filter(equipGroup => equipGroup.id_equipament === filters.equipmentTypeId)
                .map(equipGroup => ({ label: equipGroup.name, value: equipGroup.id })),
            )}
          </PF2MSearchSelectOutlined>
        </FormControl>

        <EquipmentListComponent
          equipmentFilter={filters}
          equipmentsInfo={equipmentInfo}
        />
      </div>
    </form>
  );
});

export default function CollapseTruckList({ children, classes }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const processDisptachIndicatorsTime = (
    usePlatformConfiguration('process_dispatch_indicators_time')?.value || '0'
  );

  const [filters, setFilters] = useEquipmentListFilters();

  useEffect(() => {
    dispatch(getEquipmentTypes());
    dispatch(getEquipmentGroups());
    dispatch(getTruckIndicators());
    dispatch(getLoaderIndicators());
  }, [dispatch]);

  useEffect(() => {
    if (processDisptachIndicatorsTime > 1) {
      const interval = setTimeout(() => {
        dispatch(getTruckIndicators());
        dispatch(getLoaderIndicators());
      }, processDisptachIndicatorsTime * 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [processDisptachIndicatorsTime, dispatch]);

  return (
    <div className={classes.container} style={{ display: 'flex', flexDirection: 'row' }}>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit orientation="horizontal">
        <DispatchEquipmentDetailList filters={filters} setFilters={setFilters} />
      </Collapse>
      <button onClick={() => setIsExpanded(a => !a)} type="button">
        {isExpanded ? '<' : '>'}
      </button>
      {children}
    </div>
  );
}

CollapseTruckList.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired),
  ]).isRequired,
};
