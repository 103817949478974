import React, { useState, useEffect, useMemo } from 'react';
import {
  CircularProgress, Fab, FormControl, FormGroup, FormLabel, Grid, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Clear, Done } from '@material-ui/icons';
import {
  getEquipmentTypes, getEquipmentsGroups, getCodesRawRulesMatch,
  getEquipments,
  setCodeMigrationRulesMatchValues,
  updateCodeMigrationRulesDailyParts,
  changeAllCodeMigrationRulesMatchAnalysis,
  getCodes,
} from '~/store/manager/actions';
import PF2MButton from '~/components/PF2MButton';
import PF2MDataTable, { icons } from '~/components/PF2MDataTable';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import PF2MAutocomplete from '~/components/PF2MAutocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { MTableBodyRow } from 'material-table';
import styles from '../styles';

const CodeMigrationTab = ({
  classes,
  getCodesRawRulesMatch: loadCodesRawRulesMatch,
}) => {
  const [searchDailyParts, setSearchDailyParts] = useState(false);
  const [codeMigrationsFilter, setCodeMigrationsFilter] = useState({
    equipmentType: [],
    equipmentGroup: [],
    equipment: [],
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  });
  const { t: translate } = useTranslation();

  const codesRawRulesMatchState = useSelector(state => state.manager.codesRawRulesMatch);
  const dailyPartsFilters = useSelector(state => state.dailyParts.filters);
  const loadingMigrationCodeMatch = useSelector(state => state.manager.loadingMigrationCodeMatch);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const codes = useSelector(state => state.manager.codes);

  const dispatch = useDispatch();

  const handleFilters = (data, filters) => {
    let filteredData = data;
    if (filters.equipmentType.length > 0) {
      filteredData = filteredData.filter(c => filters.equipmentType.some(
        e => e.value === c.equip_type_id || e === c.equip_type_id,
      ));
    }
    if (filters.equipmentGroup.length > 0) {
      filteredData = filteredData.filter(c => filters.equipmentGroup.some(
        e => e.value === c.equip_group_id || e === c.equip_group_id,
      ));
    }
    if (filters.equipment.length > 0) {
      filteredData = filteredData.filter(c => filters.equipment.some(
        e => e.value === c.equip_id || e === c.equip_id,
      ));
    }
    if (filters.startDate) {
      filteredData = filteredData.filter(
        c => c.start_timestamp >= filters.startDate.unix(),
      );
    }
    if (filters.endDate) {
      filteredData = filteredData.filter(
        c => c.old_end_timestamp <= filters.endDate.unix(),
      );
    }
    return filteredData;
  };

  const codesRawRulesMatch = useMemo(
    () => handleFilters(codesRawRulesMatchState, codeMigrationsFilter).map(
      c => ({
        ...c,
        start_timestamp: c.start_timestamp * 1000,
        old_end_timestamp: c.old_end_timestamp * 1000,
        time_to_new_code: c.time_to_new_code * 1000,
      }),
    ),
    [codesRawRulesMatchState, codeMigrationsFilter],
  );

  const handleChangeFilter = (name, value) => {
    setCodeMigrationsFilter(f => ({ ...f, [name]: value }));
  };

  const getLabel = (id, list) => {
    const item = list.find(i => i.id === id);
    return item ? item.name : '';
  };

  useEffect(() => {
    const prepareFiltersFromDailyParts = (filters) => {
      if (!filters.equipmentType) {
        return;
      }
      setCodeMigrationsFilter({
        ...filters,
        equipmentType: [{
          value: filters.equipmentType,
          label: getLabel(filters.equipmentType, equipmentTypes),
        }],
        equipmentGroup: filters.equipmentGroup ? [{
          value: filters.equipmentGroup,
          label: getLabel(filters.equipmentGroup, equipmentGroups),
        }] : [],
        equipment: filters.equipment ? [{
          value: filters.equipment,
          label: getLabel(filters.equipment, equipments),
        }] : [],
        startDate: filters.startDate
          ? moment(filters.startDate).startOf('day')
          : moment().startOf('day'),
        endDate: filters.endDate
          ? moment(filters.endDate).endOf('day')
          : moment().endOf('day'),
      });
    };

    if (dailyPartsFilters.equipmentType) {
      prepareFiltersFromDailyParts(dailyPartsFilters);
    }
  }, [dailyPartsFilters, equipments, equipmentTypes, equipmentGroups, dispatch]);

  useEffect(() => {
    loadCodesRawRulesMatch();

    if (equipments.length === 0) {
      dispatch(getEquipments());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getCodes());
    }
  }, [loadCodesRawRulesMatch, equipments.length, dispatch]);

  const renderAcceptOrDenyCell = rowData => (
    <div>
      <Fab
        size="small"
        color="secondary"
        aria-label="Check"
        className={classes.fab}
        onClick={() => {
          dispatch(setCodeMigrationRulesMatchValues({
            ...rowData,
            analisys_status: true,
            update_status: 'U',
            start_timestamp: rowData.start_timestamp / 1000,
            old_end_timestamp: rowData.old_end_timestamp / 1000,
            time_to_new_code: rowData.time_to_new_code / 1000,
          }));
        }}
      >
        <Done />
      </Fab>
      <Fab
        size="small"
        color="secondary"
        aria-label="Deny"
        className={classes.fab}
        onClick={() => {
          dispatch(setCodeMigrationRulesMatchValues({
            ...rowData,
            analisys_status: false,
            update_status: 'U',
            start_timestamp: rowData.start_timestamp / 1000,
            old_end_timestamp: rowData.old_end_timestamp / 1000,
            time_to_new_code: rowData.time_to_new_code / 1000,
          }));
        }}
      >
        <Clear />
      </Fab>
    </div>
  );

  const renderHeader = () => (
    <Formik
      onSubmit={async () => {
        dispatch(getCodesRawRulesMatch());
      }}
    >
      {({
        submitForm,
      }) => (
        <Form style={{ display: 'flex', minHeight: 75 }}>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:StartDate')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="startDate"
                render={({ field }) => (
                  <KeyboardDatePicker
                    {...field}
                    clearable
                    inputVariant="outlined"
                    disableFuture
                    InputProps={{
                      style: {
                        height: 40,
                        width: 200,
                        color: '#647886',
                      },
                    }}
                    value={codeMigrationsFilter.startDate}
                    format={translate('date:DateFormat')}
                    autoOk
                    onChange={(e) => {
                      handleChangeFilter('startDate', e);
                    }}
                    invalidDateMessage={translate('validation:InvalidDate')}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:EndDate')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="endDate"
                render={({ field }) => (
                  <KeyboardDatePicker
                    {...field}
                    clearable
                    inputVariant="outlined"
                    disableFuture
                    InputProps={{
                      style: {
                        height: 40,
                        width: 200,
                        color: '#647886',
                      },
                    }}
                    value={codeMigrationsFilter.endDate}
                    format={translate('date:DateFormat')}
                    autoOk
                    onChange={(e) => {
                      handleChangeFilter('endDate', e);
                    }}
                    invalidDateMessage={translate('validation:InvalidDate')}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:EquipmentType')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="equipmentType"
                render={() => (
                  <PF2MAutocomplete
                    options={
                      equipmentTypes
                        .map(e => ({ value: e.id, label: e.name }))
                    }
                    onChange={(_, v) => {
                      handleChangeFilter('equipmentType', v);
                    }}
                    value={codeMigrationsFilter.equipmentType}
                    limitTags={1}
                  />
                )}
              />
              <ErrorMessage
                name="equipmentType"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:EquipmentGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="equipmentGroup"
                render={() => (
                  <PF2MAutocomplete
                    options={
                      equipmentGroups
                        .filter(e => codeMigrationsFilter.equipmentType?.some(
                          t => t.value === e.id_equipament,
                        ))
                        .map(e => ({ value: e.id, label: e.name }))
                    }
                    onChange={(_, v) => {
                      handleChangeFilter('equipmentGroup', v);
                    }}
                    value={codeMigrationsFilter.equipmentGroup}
                    limitTags={1}
                  />
                )}
              />
              <ErrorMessage
                name="equipGroup"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }} className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Equipment')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="equipment"
                render={() => (
                  <PF2MAutocomplete
                    options={
                      equipments
                        .filter(e => codeMigrationsFilter.equipmentGroup?.some(
                          t => t.value === e.id_group,
                        ))
                        .map(e => ({ value: e.id, label: e.name }))
                    }
                    onChange={(_, v) => {
                      handleChangeFilter('equipment', v);
                    }}
                    value={codeMigrationsFilter.equipment}
                    limitTags={1}
                  />
                )}
              />
              <ErrorMessage
                name="equip"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MSearchButton
              className={classes.searchButton}
              onClick={submitForm}
            />
          </div>
        </Form>
      )}
    </Formik>
  );

  const columns = [
    {
      field: 'accept',
      title: translate('common:Accept'),
      render: rowData => (
        !loadingMigrationCodeMatch
          ? renderAcceptOrDenyCell(rowData)
          : <CircularProgress color="secondary" size={24} />
      ),
    },
    {
      customType: 'filterLookup',
      title: translate('common:EquipmentGroupMatch'),
      field: 'equip_group_id',
      lookup: equipmentGroups?.map(e => ({ ...e, name: e.name })),
    },
    {
      title: translate('common:EquipmentMach'),
      customType: 'filterLookup',
      field: 'equip_id',
      lookup: equipments,
    },
    {
      editable: 'never',
      title: translate('common:DateMatch'),
      field: 'start_timestamp',
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: 'never',
      title: translate('common:EndDateMatch'),
      field: 'old_end_timestamp',
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: 'never',
      title: translate('common:OriginCodeMatch'),
      field: 'origin_codes_id',
      customType: 'filterLookup',
      lookupKey: 'id_pk',
      lookup: codes,
    },
    {
      editable: 'never',
      title: translate('common:DurationMatch'),
      field: 'duration',
      render: rowData => `${(Math.abs(rowData.old_end_timestamp - rowData.start_timestamp) / 60000).toFixed(2)} ${translate('common:Min')}`,
    },
    {
      editable: 'never',
      title: translate('common:NewCurrentDuration'),
      field: 'duration',
      render: rowData => `${(rowData.time_to_new_code / 60000).toFixed(2)} ${translate('common:Min')}`,
    },
    {
      editable: 'never',
      title: translate('common:DestinationCodeMatch'),
      field: 'destination_codes_id',
      customType: 'filterLookup',
      lookupKey: 'id_pk',
      lookup: codes,
    },
    {
      editable: 'never',
      title: translate('common:NewCodeDuration'),
      field: 'duration',
      render: rowData => `${((Math.abs(rowData.old_end_timestamp - rowData.start_timestamp) - rowData.time_to_new_code) / 60000).toFixed(2)} ${translate('common:Min')}`,
    },
  ];

  const onCommitChanges = () => {
    const filteredCodesRules = codesRawRulesMatch.filter(
      (crrm => crrm.update_status === 'U'),
    );

    const normalizeTimestamp = filteredCodesRules.map(
      (crrm => ({
        ...crrm,
        start_timestamp: crrm.start_timestamp / 1000,
        old_end_timestamp: crrm.old_end_timestamp / 1000,
        time_to_new_code: crrm.time_to_new_code / 1000,
      })),
    );

    dispatch(updateCodeMigrationRulesDailyParts(normalizeTimestamp));
  };

  return (
    <div className={classes.tabContainer}>
      <Grid container spacing={2} className={classes.dailyPartsSelection}>
        <Grid item xs={1}>
          <PF2MButton
            className={!searchDailyParts ? classes.unSelectedButton : ''}
            exact="true"
            href="/parts"
            // onClick={() => setSearchDailyParts(true)}
          >
            {translate('common:DailyParts')}
          </PF2MButton>
        </Grid>
        <Grid item xs={1}>
          <PF2MButton
            className={searchDailyParts ? classes.unSelectedButton : ''}
            onClick={() => setSearchDailyParts(false)}
          >
            {translate('common:CodeMigrations')}
          </PF2MButton>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12}>
          {renderHeader()}
          <PF2MDataTable
            defaultEditable={false}
            options={{
              search: false,
              selection: false,
              hideDelete: true,
              hideCommit: true,
              hideReload: true,
              hideAdd: true,
            }}
            actions={[
              {
                icon: icons.CheckCircle,
                isFreeAction: true,
                tooltip: translate('common:Commit Changes'),
                onClick: onCommitChanges,
              },
              {
                icon: icons.Check,
                isFreeAction: true,
                tooltip: translate('common:Accept All'),
                onClick: () => {
                  dispatch(changeAllCodeMigrationRulesMatchAnalysis(true, codesRawRulesMatch));
                },
              },
              {
                icon: icons.Clear,
                isFreeAction: true,
                tooltip: translate('common:Reject All'),
                onClick: () => {
                  dispatch(changeAllCodeMigrationRulesMatchAnalysis(false, codesRawRulesMatch));
                },
              },
            ]}
            columns={columns}
            data={codesRawRulesMatch}
            components={{
              Row: (props) => {
                const rowStyles = { true: classes.activeRow, false: classes.inactiveRow, undefined: '' };
                return (
                  <MTableBodyRow
                    {...props}
                    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
                    className={rowStyles[props.data.analisys_status]}
                  />
                );
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CodeMigrationTab.propTypes = {
  classes: PropTypes.object.isRequired,
  fuels: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  getCodesRawRulesMatch: PropTypes.func,
};

CodeMigrationTab.defaultProps = {
  fuels: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  getCodesRawRulesMatch: null,
};

const mapStateToProps = state => ({
  codesRawRulesMatch: state.manager.codesRawRulesMatch,
});

const mapDispatchToProps = {
  getCodesRawRulesMatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CodeMigrationTab));
