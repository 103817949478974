import React, { useState, useEffect } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Accordion,
  Typography,
  AccordionDetails,
  styled,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import styles from '../styles';
import { AllocationModal } from './AllocationModal';
import AllocationRow from './AllocationRow';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MEquipmentPool from '~/components/PF2MEquipmentPool';
import {
  getTabletConfiguration,
} from '~/store/manager/actions';
import {
  getEquipmentTypes,
  getEquipmentGroups,
  getEquipments,
  getElements,
  getSubElements,
  getMaterials,

} from '~/store/nref/actions';
import {
  setSelectedPerforatorAllocation,
  getLoadAllocations,
  getTruckAllocations,
  deallocateAllLoadAllocations,
  getEquipmentScheduleSubelements,
  getPerforatorEquipmentScheduleList,
} from '~/store/dispatch/actions';

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    {...props}
  />
))(() => ({
  minHeight: '10px !important',
  '& .MuiAccordionSummary-content': {
    margin: 5,
  },
}));

const PERFORATOR = 4;
const AllocationsTab = ({ classes }) => {
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  const { t: translate } = useTranslation();

  const equipmentTypes = useSelector(
    state => state.nref.equipmentTypes.filter(r => [PERFORATOR].includes(r.id)),
  );
  const equipmentGroups = useSelector(state => state.nref.equipmentGroups);
  const equipments = useSelector(state => state.nref.equipments
    .filter(r => (r.id_equip === PERFORATOR))
    .map(r => ({
      ...r,
      equip_name: r.name,
      equip_type_id: r.id_equip,
    })));
  const perforatorEquipmentScheduleList = useSelector(
    state => state.dispatch.perforatorEquipmentScheduleList,
  );
  const equipmentScheduleSubelements = useSelector(
    state => state.dispatch.equipmentScheduleSubelements,
  );
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [poolEquipmentType, setPoolEquipmentType] = useState(0);
  const [poolEquipmentGroup, setPoolEquipmentGroup] = useState(0);
  const [isDeallocationAlertOpen, setIsDeallocationAlertOpen] = useState(false);
  const [isPerforatorModalOpen, setIsPerforatorModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    async function fetchData() {
      dispatch(getPerforatorEquipmentScheduleList({ equip_type: PERFORATOR }));
      dispatch(getEquipmentScheduleSubelements({ equip_type: PERFORATOR }));
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentGroups());
      dispatch(getEquipments());
      dispatch(getMaterials());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getTabletConfiguration());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (tabletConfiguration.find(k => k.key === 'dispatch_update_time')) {
      const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time').value * 1000;
      const interval = setInterval(() => {
        setDataLoaded(false);
      }, time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [tabletConfiguration]);

  const renderEmptyRow = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 90,
        backgroundColor: '#F9F9F9',
        color: '#647886',
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
      }}
    >
      <span>{translate('common:NoRegisteredAllocations')}</span>
    </div>
  );

  const renderPoolFilters = () => (
    <div style={{ width: '15%' }}>
      <FormControl style={{ marginTop: 10 }}>
        <FormLabel className={classes.formLabel}>
          {`${translate('common:EquipmentType')}:`}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={poolEquipmentType}
            placeholder={null}
            onChange={(e) => {
              setPoolEquipmentType(e.target.value);
              setPoolEquipmentGroup(0);
            }}
            className={classnames(classes.field)}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentTypes
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl style={{ marginTop: 10 }}>
        <FormLabel className={classes.formLabel}>
          {`${translate('common:EquipmentGroup')}:`}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={poolEquipmentGroup}
            placeholder={null}
            onChange={(e) => {
              setPoolEquipmentGroup(e.target.value);
            }}
            className={classnames(classes.field)}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentGroups
                .filter(r => r.id_equipament === poolEquipmentType)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  const renderPools = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      {renderPoolFilters()}
      <div style={{
        display: 'flex',
        marginTop: 10,
        width: '85%',
      }}
      >
        <PF2MEquipmentPool
          equips={equipments.map(r => ({ ...r, equip_id: r.id }))
            .filter(e => (poolEquipmentGroup ? e.id_group === poolEquipmentGroup : true))}
          onEquipClick={(equip) => {
            if (equip.id_equip === PERFORATOR) {
              dispatch(setSelectedPerforatorAllocation(equip));
              setIsPerforatorModalOpen(true);
            }
          }}
          headerText={translate('common:AvailableEquipments').toUpperCase()}
        />
      </div>
    </div>
  );

  const renderTableHeader = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      <div style={{
        width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:Front')}</span>
      </div>
      <div style={{
        width: '65%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:Equipments')}</span>
      </div>
    </div>
  );

  const renderAllocationTable = () => (
    <div style={{ width: '100%', maxHeight: handle.active ? 750 : 500 }}>
      {renderTableHeader()}
      <PF2MScrollbar style={{
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        maxHeight: expanded ? 450 : 650,
        transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        height: 'auto',
      }}
      >
        <>
          {
            equipmentScheduleSubelements?.length
              ? equipmentScheduleSubelements.map(
                r => (
                  <AllocationRow
                    key={r.id}
                    subelement={r}
                    perforators={perforatorEquipmentScheduleList.filter(
                      x => x.flowchart_element_point_id === r.id,
                    )}
                    onEquipClick={(sched) => {
                      if (sched.equipaments_id === PERFORATOR) {
                        dispatch(setSelectedPerforatorAllocation({
                          ...equipments.find(
                            e => e.id === sched.group_equipament_links_id,
                          ),
                          sched_id: sched.id,
                          equip_id: sched.group_equipament_links_id,
                        }));
                        setIsPerforatorModalOpen(true);
                      }
                    }}
                  />
                ),
              )
              : renderEmptyRow()
          }
        </>
      </PF2MScrollbar>
    </div>
  );


  return (
    <FullScreen handle={handle}>
      <div className={classes.tabContainer}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '86vh',
          }}
        >
          {renderAllocationTable()}
          <Accordion
            defaultExpanded
            onChange={() => setExpanded(!expanded)}
            expanded={expanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="1"
            >
              <Typography>{ translate('common:AvailableEquipments') }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderPools()}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <AllocationModal
        modalData={isPerforatorModalOpen}
        closeModal={() => setIsPerforatorModalOpen(false)}
      />

      <PF2MAlertDialog
        hasCancel
        onClose={() => setIsDeallocationAlertOpen(false)}
        confirmText={translate('common:Yes')}
        description={translate('validation:ThisActionWillClearAllYourAllocations')}
        open={isDeallocationAlertOpen}
        onConfirm={() => {
          setIsDeallocationAlertOpen(false);
          dispatch(deallocateAllLoadAllocations());
        }}
      />
    </FullScreen>
  );
};

AllocationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

AllocationsTab.defaultProps = {};

export default withStyles(styles)(AllocationsTab);
