
// eslint-disable-next-line import/prefer-default-export
export const getFilterStates = translate => ({
  equipaments: [{ name: 'equipmenttypes', label: translate('common:EquipmentType'), state: 'equipmentTypes' }],
  equipament_groups: [
    {
      name: 'equipmenttypes',
      label: translate('common:EquipmentType'),
    },
    {
      name: 'equipmentsgroups',
      parent: 'equipmenttypes',
      key: 'id_equipament',
      label: translate('common:EquipmentGroup'),
      state: 'equipmentsGroups',
    }],
  group_equipament_links: [
    {
      name: 'equipmenttypes',
      label: translate('common:EquipmentType'),
    },
    {
      name: 'equipmentsgroups',
      parent: 'equipmenttypes',
      key: 'id_equipament',
      label: translate('common:EquipmentGroup'),
    },
    {
      name: 'equipments',
      parent: 'equipmentsgroups',
      key: 'id_group',
      label: translate('common:Equipment'),
      state: 'equipments',
    }],
  flowchart_element_points: [
    {
      name: 'elements',
      label: translate('common:Elements'),
    },
    {
      name: 'subelements',
      parent: 'elements',
      key: 'id_element',
      label: translate('common:SubElements'),
      state: 'subElements',
    }],
  material_groups: [{
    name: 'materialsgroups',
    label: translate('common:MaterialGroup'),
    state: 'materialsGroups',
  }],
  materials: [{
    name: 'materialsgroups',
    label: translate('common:MaterialGroup'),
  },
  {
    name: 'materials',
    parent: 'materialsgroups',
    key: 'id_group',
    label: translate('common:Materials'),
    state: 'materials',
  }],
  content: [{
    name: 'contents',
    label: translate('common:Content'),
    state: 'contents',
  }],
  code_types: [{
    name: 'codetypes',
    label: translate('common:CodeTypes'),
    state: 'codeTypes',
  }],
  code_groups: [
    {
      name: 'codetypes',
      label: translate('common:CodeTypes'),
    },
    {
      name: 'codegroups',
      parent: 'codetypes',
      key: 'code_type',
      label: translate('common:CodeGroups'),
      state: 'codeGroups',
    }],
  codes: [
    {
      name: 'codetypes',
      label: translate('common:CodeTypes'),
    },
    {
      name: 'codegroups',
      parent: 'codetypes',
      key: 'code_type',
      label: translate('common:CodeGroups'),
    },
    {
      name: 'codes',
      parent: 'codegroups',
      key: 'id_group',
      label: translate('common:Codes'),
      state: 'codes',
    }],
  goal_indicators: [{
    name: 'goalindicators',
    label: translate('common:Indicators'),
    state: 'goalIndicators',
  }],
  unitary_operation_group: [{
    name: 'unitaryoperationgroup',
    label: translate('common:UnitOpGroup'),
    state: 'unitOperationGroups',
  }],
  unitary_operation: [
    {
      name: 'unitaryoperationgroup',
      label: translate('common:UnitOpGroup'),
    },
    {
      name: 'unitaryoperation',
      parent: 'unitaryoperationgroup',
      key: 'id_unitary_operation_group',
      label: translate('common:UnitOperation'),
      state: 'unitOperations',
    }],
  drillhole_types: [{
    name: 'drillholetype',
    label: translate('common:HoleType'),
    state: 'drillholeTypes',
  }],
  handling_types: [{
    name: 'handlingtypes',
    label: translate('common:MovementType'),
    state: 'handlingTypes',
  }],
  operation_type: [
    {
      name: 'equipmenttypes',
      label: translate('common:EquipmentType'),
    },
    {
      name: 'operationtype',
      parent: 'equipmenttypes',
      key: 'id_equipment_type',
      label: translate('common:OperationType'),
      state: 'operationTypes',
    }],
  operation_type_activity: [
    {
      name: 'equipmenttypes',
      label: translate('common:EquipmentType'),
    },
    {
      name: 'operationtype',
      parent: 'equipmenttypes',
      key: 'id_equipment_type',
      label: translate('common:OperationType'),
    },
    {
      name: 'operationtypeactivity',
      parent: 'operationtype',
      key: 'operation_type_id',
      label: translate('common:OperationTypeActivity'),
      state: 'operationTypeActivities',
    }],
  team: [
    {
      name: 'teamgroups',
      label: translate('common:TeamGroups'),
    },
    {
      name: 'team',
      parent: 'teamgroups',
      label: translate('common:Team'),
      key: 'id_team_group',
      state: 'teams',
    }],
  turns: [
    {
      name: 'turnsgroups',
      label: translate('common:ShiftsGroup'),
    },
    {
      name: 'turns',
      parent: 'turnsgroups',
      label: translate('common:Shift'),
      key: 'id_turn_group',
      state: 'turns',
    }],
});
