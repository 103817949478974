import React, { useEffect, useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CleanFilterIcon from '~/assets/clean_filter.svg';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getOperators,
  getOperatorsGroups,
} from '~/store/manager/actions';
import {
  getElements,
  getSubElements,
  getCodes,
  getCodeGroups,
  getCodeTypes,
  getNrefOperators,
  getNrefOperatorsGroups,
  getEquipmentTypes,
  getEquipmentGroups,
  getEquipments,
} from '~/store/nref/actions';
import {
  getCodeTypes as getMaintenanceCodeTypes,
  getCodeGroups as getMaintenanceCodeGroups,
  getCodes as getMaintenanceCodes,
} from '~/store/maintenance/actions';
import {
  setCurrentEquipmentStatusFilter,
  restoreEquipmentStatusFilter,
} from '~/store/dispatch/actions';

const EquipmentStatusFilter = ({ classes, showEditAllModal, selectedItems }) => {
  const { t: translate } = useTranslation();
  const equipmentTypes = useSelector(state => state.nref.equipmentTypes);
  const equipmentGroups = useSelector(state => state.nref.equipmentGroups);
  const equipments = useSelector(state => state.nref.equipments);
  const codeTypes = useSelector(state => state.nref.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.nref.codeGroups);
  const codes = useSelector(state => state.nref.codes);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentStatusFilter);

  const buttonEditAllDisable = !selectedItems.length || !currentFilter.equip_type;

  const dispatch = useDispatch();
  const [domainsLoaded, setDomainsLoaded] = useState(false);

  useEffect(() => {
    async function loadDomains() {
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentGroups());
      dispatch(getEquipments());
      dispatch(getCodeTypes());
      dispatch(getCodeGroups());
      dispatch(getCodes());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getOperators());
      dispatch(getOperatorsGroups());
      dispatch(getNrefOperators());
      dispatch(getNrefOperatorsGroups());
      dispatch(getMaintenanceCodeTypes());
      dispatch(getMaintenanceCodeGroups());
      dispatch(getMaintenanceCodes());
    }
    if (!domainsLoaded) {
      loadDomains();
      setDomainsLoaded(true);
    }
  }, [dispatch, domainsLoaded]);

  const handleChange = (field, value) => {
    if (currentFilter[field] === value) return;
    switch (field) {
      case 'equip_type':
        dispatch(setCurrentEquipmentStatusFilter(field, value));
        if (value === 0) break;
        dispatch(setCurrentEquipmentStatusFilter('id_equip_group', 0));
        dispatch(setCurrentEquipmentStatusFilter('id_equip', 0));
        break;
      case 'id_equip_group':
        dispatch(setCurrentEquipmentStatusFilter(field, value));
        if (value === 0) break;
        dispatch(setCurrentEquipmentStatusFilter('id_equip', 0));
        break;
      case 'code_type':
        dispatch(setCurrentEquipmentStatusFilter(field, value));
        if (value === 0) break;
        dispatch(setCurrentEquipmentStatusFilter('id_code_group', 0));
        dispatch(setCurrentEquipmentStatusFilter('id_code', 0));
        break;
      case 'id_code_group':
        dispatch(setCurrentEquipmentStatusFilter(field, value));
        if (value === 0) break;
        dispatch(setCurrentEquipmentStatusFilter('id_code', 0));
        break;
      case 'element_id':
        dispatch(setCurrentEquipmentStatusFilter(field, value));
        if (value === 0) break;
        dispatch(setCurrentEquipmentStatusFilter('point_id', 0));
        break;
      default:
        dispatch(setCurrentEquipmentStatusFilter(field, value));
    }
  };

  const initValue = { value: 0, label: translate('common:All') };
  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = initValue,
    clearedValue = 0,
  ) => (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          error={buttonEditAllDisable ? 'error' : ''}
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key]}
          onChange={e => handleChange(key, e.target.value)}
          clearedValue={clearedValue}
        >
          {(initialValue ? [initialValue] : [])
            .concat(collection
              .map(e => ({ value: e.id, label: e.name || '0' })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, minmax(200px, 1fr))',
      gridRowGap: 10,
    }}
    >
      {renderFormControl(currentFilter, 'equip_type', `${translate('common:EquipmentType')} *`, equipmentTypes, { label: translate('common:Select'), value: 0 })}
      {renderFormControl(currentFilter, 'id_equip_group', translate('common:EquipmentGroup'), equipmentGroups
        .filter(c => currentFilter.equip_type === 0
          || c.id_equipament === currentFilter.equip_type))}
      {renderFormControl(currentFilter, 'id_equip', translate('common:Equipment'), equipments
        .filter(c => currentFilter.id_equip_group === 0
          || c.id_group === currentFilter.id_equip_group))}
      {renderFormControl(currentFilter, 'code_type', translate('common:CodeType'), codeTypes, { ...initValue, value: -1 }, -1)}
      <FormControl>
        <FormGroup>
          <PF2MButton
            color="primary"
            onClick={() => dispatch(restoreEquipmentStatusFilter())}
            style={{ marginTop: 15 }}
          >
            <img src={CleanFilterIcon} className={classes.icon} alt="" />
            {translate('common:ClearFilters')}
          </PF2MButton>
        </FormGroup>
      </FormControl>
      {renderFormControl(currentFilter, 'id_code_group', translate('common:CodeGroup'), codeGroups
        .filter(c => (c.id_equip === currentFilter.equip_type)
          && (currentFilter.code_type === -1 || c.code_type === currentFilter.code_type)))}
      {renderFormControl(currentFilter, 'id_code', translate('common:Code'), codes
        .filter(c => (c.id_equip === currentFilter.equip_type)
          && (c.code_type === currentFilter.code_type)
          && (currentFilter.id_code_group === 0 || c.id_group === currentFilter.id_code_group)))}
      {renderFormControl(currentFilter, 'element_id', translate('common:Element'), elements)}
      {renderFormControl(currentFilter, 'point_id', translate('common:SubElement'), subElements
        .filter(c => currentFilter.element_id === 0
          || c.id_element === currentFilter.element_id))}
      <FormControl>
        <FormGroup>
          <PF2MButton
            color="primary"
            onClick={() => showEditAllModal(true)}
            style={{ marginTop: 15 }}
            disabled={buttonEditAllDisable}
          >
            {translate('common:EditEntries')}
            &nbsp;
            (
            {selectedItems.length}
            )
          </PF2MButton>
        </FormGroup>
      </FormControl>
    </div>
  );
};

EquipmentStatusFilter.propTypes = {
  selectedItems: PropTypes.array,
  classes: PropTypes.object.isRequired,
  showEditAllModal: PropTypes.func,
};
EquipmentStatusFilter.defaultProps = {
  selectedItems: [],
  showEditAllModal: null,
};

export default withStyles(styles)(EquipmentStatusFilter);
