/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { handleErrors } from '../common';

const setElements = elements => ({
  type: types.SET_NREF_ELEMENTS,
  elements,
});

const setSubElements = subElements => ({
  type: types.SET_NREF_SUBELEMENTS,
  subElements,
});

const setCodes = codes => ({
  type: types.SET_NREF_CODES,
  codes,
});

const setCodeGroups = codeGroups => ({
  type: types.SET_NREF_CODE_GROUPS,
  codeGroups,
});

const setCodeTypes = codeTypes => ({
  type: types.SET_NREF_CODE_TYPES,
  codeTypes,
});

const setOperators = operators => ({
  type: types.SET_NREF_OPERATORS,
  operators,
});

const setOperatorsGroups = operatorsGroups => ({
  type: types.SET_NREF_OPERATORS_GROUPS,
  operatorsGroups,
});

const setEquipmentGroups = equipmentGroups => ({
  type: types.SET_NREF_EQUIPMENT_GROUPS,
  equipmentGroups,
});

const setEquipmentTypes = equipmentTypes => ({
  type: types.SET_NREF_EQUIPMENT_TYPES,
  equipmentTypes,
});

const setEquipments = equipments => ({
  type: types.SET_NREF_EQUIPMENTS,
  equipments,
});


const setMaterials = materials => ({
  type: types.SET_NREF_MATERIALS,
  materials,
});

export const getElements = () => async (dispatch, _getState) => {
  // if (getState().nref.elements.length > 0) return;
  api.get('/nref/elements')
    .then(res => dispatch(setElements(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getSubElements = () => async (dispatch, _getState) => {
  // if (getState().nref.subElements.length > 0) return;
  api.get('/nref/subelements')
    .then(res => dispatch(setSubElements(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getCodes = () => async (dispatch, _getState) => {
  api.get('/nref/codes')
    .then(res => dispatch(setCodes(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getCodeGroups = () => async (dispatch, _getState) => {
  api.get('/nref/codegroups')
    .then(res => dispatch(setCodeGroups(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getCodeTypes = () => async (dispatch, _getState) => {
  api.get('/nref/codetypes')
    .then(res => dispatch(setCodeTypes(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getNrefOperators = () => async (dispatch, _getState) => {
  api.get('/nref/operators')
    .then(res => dispatch(setOperators(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getNrefOperatorsGroups = () => async (dispatch, _getState) => {
  api.get('/nref/operatorsgroups')
    .then(res => dispatch(setOperatorsGroups(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};
export const getMaterials = () => async (dispatch, _getState) => {
  api.get('/nref/materials')
    .then(res => dispatch(setMaterials(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};
export const getEquipmentGroups = () => async (dispatch, _getState) => {
  api.get('/nref/equipmentgroups')
    .then(res => dispatch(setEquipmentGroups(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getEquipmentTypes = () => async (dispatch, _getState) => {
  api.get('/nref/equipmenttypes')
    .then(res => dispatch(setEquipmentTypes(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getEquipments = () => async (dispatch, _getState) => {
  api.get('/nref/equipments')
    .then(res => dispatch(setEquipments(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};
