import * as types from './types';

const INITIAL_STATE = {
  elements: [],
  subElements: [],
  codes: [],
  codeGroups: [],
  codeTypes: [],
  operators: [],
  operatorsGroups: [],
  materials: [],
  equipmentGroups: [],
  equipmentTypes: [],
  equipments: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_NREF_ELEMENTS: {
      return { ...state, elements: action.elements };
    }
    case types.SET_NREF_SUBELEMENTS: {
      return { ...state, subElements: action.subElements };
    }
    case types.SET_NREF_CODES: {
      return { ...state, codes: action.codes };
    }
    case types.SET_NREF_CODE_GROUPS: {
      return { ...state, codeGroups: action.codeGroups };
    }
    case types.SET_NREF_CODE_TYPES: {
      return { ...state, codeTypes: action.codeTypes };
    }
    case types.SET_NREF_OPERATORS: {
      return { ...state, operators: action.operators };
    }
    case types.SET_NREF_OPERATORS_GROUPS: {
      return { ...state, operatorsGroups: action.operatorsGroups };
    }
    case types.SET_NREF_MATERIALS: {
      return { ...state, materials: action.materials };
    }
    case types.SET_NREF_EQUIPMENT_GROUPS: {
      return { ...state, equipmentGroups: action.equipmentGroups };
    }
    case types.SET_NREF_EQUIPMENT_TYPES: {
      return { ...state, equipmentTypes: action.equipmentTypes };
    }
    case types.SET_NREF_EQUIPMENTS: {
      return { ...state, equipments: action.equipments };
    }
    default: {
      return state;
    }
  }
}
