import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { useTranslation } from 'react-i18next';

import {
  getMaterialsTemplate,
  exportMaterials,
  importMaterials,
  getMaterials,
  resetFileUploadErrors,
} from '~/store/manager/actions';

const MaterialsTab = ({
  classes,
  materials,
  getMaterialsTemplate: getTemplateAction,
  exportMaterials: exportAction,
  importMaterials: importAction,
  getMaterials: getAll,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showDialogImport, setShowDialogImport] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchOperators();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
    }
  }, [fileUploadErrors]);

  const columns = [
    { field: 'name', title: translate('common:Name') },
    { field: 'active_name', title: 'STATUS' },
    {
      field: 'group_name',
      title: translate('common:Group'),
      width: '100%',
      wrap: true,
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={materials}
        columns={columns}
      />
      <Footer />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
    </div>
  );
};

MaterialsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  materials: PropTypes.array,
  getMaterialsTemplate: PropTypes.func,
  exportMaterials: PropTypes.func,
  importMaterials: PropTypes.func,
  getMaterials: PropTypes.func,
};

MaterialsTab.defaultProps = {
  materials: [],
  getMaterialsTemplate: () => {},
  exportMaterials: () => {},
  importMaterials: () => {},
  getMaterials: () => {},
};

const mapStateToProps = state => ({
  materials: state.manager.materials,
});

const mapDispatchToProps = {
  getMaterialsTemplate,
  exportMaterials,
  importMaterials,
  getMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MaterialsTab));
