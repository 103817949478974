import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MTable from '~/components/PF2MTable';
import {
  getEquipmentStatuses, setCurrentEquipmentStatus, setOriginalCurrentEquipmentStatus,
} from '~/store/dispatch/actions';
import EquipmentStatusFilter from '../EquipmentStatusFilter';
import EquipmentStatusEditModal from '../EquipmentStatusEditModal';
import EquipmentStatusEditAllModal from '../EquipmentStatusEditAllModal';
import { getTabletConfiguration } from '~/store/manager/actions';
import { usePlatformConfigurationAuth } from '~/services/platformConfiguration';

const EquipmentStatusTab = ({ classes }) => {
  const { t: translate } = useTranslation();
  const isCurrentUpdated = useSelector(state => state.dispatch.isCurrentEquipmentStatusUpdated);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentStatusFilter);
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const equipments = useSelector(state => state.nref.equipments);
  const codeTypes = useSelector(state => state.nref.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.nref.codeGroups);
  const codes = useSelector(state => state.nref.codes);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const operators = useSelector(state => state.nref.operators);
  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses.map(e => ({
    ...e,
    equip_name: equipments.find(eq => eq.id === e.id_equip)?.name,
    operator_name: operators.find(eq => eq.id_operator === e.id_operator)?.name,
    code_group_name: codeGroups.find(eq => eq.id === e.id_code_group)?.name,
    code_name: codes.find(eq => eq.id === e.id_code)?.name,
    element_name: elements.find(eq => eq.id === e.element_id)?.name,
    point_name: subElements.find(eq => eq.id === e.point_id)?.name,
  })));
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAllOpen, setModalAllOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const checkboxState = usePlatformConfigurationAuth('dispatch_equipmentstatus_checkbox') || '0';

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getEquipmentStatuses());
      dispatch(getTabletConfiguration());
      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded]);

  useEffect(() => {
    if (tabletConfiguration.find(k => k.key === 'dispatch_update_time')) {
      const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time').value * 1000;
      const interval = setInterval(() => {
        setDataLoaded(false);
      }, time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [tabletConfiguration]);

  useEffect(() => {
    if (isCurrentUpdated && modalOpen) {
      setModalOpen(false);
      dispatch(setCurrentEquipmentStatus(null));
      dispatch(setOriginalCurrentEquipmentStatus(null));
    }
  }, [isCurrentUpdated, modalOpen, dispatch]);

  useEffect(() => setSelectedItems([]), [currentFilter]);

  const columns = [
    {
      field: 'equip_name',
      title: translate('common:Equipment'),
    },
    {
      field: 'operator_name',
      title: translate('common:Operator'),
    },
    {
      field: 'code_type_name',
      title: translate('common:CodeType'),
    },
    {
      field: 'code_group_name',
      title: translate('common:CodeGroup'),
    },
    {
      field: 'code_name',
      title: translate('common:Code'),
    },
    {
      field: 'element_name',
      title: translate('common:Element'),
    },
    {
      field: 'point_name',
      title: translate('common:SubElement'),
    },
    {
      field: 'duration',
      title: translate('common:Duration'),
    },
    {
      field: 'select',
      title: '',
    },
  ];

  const data = useMemo(() => {
    // this page works with an specific equipment type
    if (!currentFilter || !currentFilter.equip_type) return [];

    const getCodeTypeName = (id) => {
      const foundCodeType = codeTypes.find(r => r.id === id);
      return foundCodeType ? foundCodeType.name : '';
    };

    return equipmentStatuses.filter(o => (
      (currentFilter.equip_type === 0 || o.equip_type === currentFilter.equip_type)
        && (currentFilter.id_equip_group === 0 || o.id_equip_group === currentFilter.id_equip_group)
        && (currentFilter.id_equip === 0 || o.id_equip === currentFilter.id_equip)
        && (currentFilter.code_type === -1 || o.code_type === currentFilter.code_type)
        && (currentFilter.id_code_group === 0 || o.id_code_group === currentFilter.id_code_group)
        && (currentFilter.id_code === 0 || o.id_code === currentFilter.id_code)
        && (currentFilter.element_id === 0 || o.element_id === currentFilter.element_id)
        && (currentFilter.point_id === 0 || o.point_id === currentFilter.point_id)
    ))
      .map(r => ({
        ...r,
        code_type_name: getCodeTypeName(r.code_type),
        duration: r.duration[0] === '-' ? '00:00:00' : r.duration,
      }));
    // eslint-disable-next-line
  }, [equipmentStatuses, currentFilter]);
  const selectedItenIds = useMemo(() => {
    const selectedSet = new Set(selectedItems.map(d => d.id_equip));
    return data.filter(d => selectedSet.has(d.id_equip)).map(({ id }) => id);
  }, [data, selectedItems]);

  const handleClose = () => {
    dispatch(setCurrentEquipmentStatus(null));
    dispatch(setOriginalCurrentEquipmentStatus(null));
    setModalOpen(false);
  };

  const handleAllClose = () => {
    setModalAllOpen(false);
  };

  const handleSelectItem = row => setSelectedItems((selItens) => {
    if (!selItens.find(sel => sel.id_equip === row.id_equip)) return [row, ...selItens];
    return selItens.filter(sel => sel.id_equip !== row.id_equip);
  });

  const handleSelectAll = items => setSelectedItems((sel) => {
    if (sel.length === items.length) return [];
    return items.map(i => ({ id: i.id, id_equip: i.id_equip }));
  });
  return (
    <div className={classes.tabContainer}>
      <EquipmentStatusFilter
        showEditAllModal={setModalAllOpen}
        selectedItems={selectedItems}
      />
      <PF2MTable
        data={data}
        columns={columns}
        updateItem={(row) => {
          dispatch(setCurrentEquipmentStatus({ ...row, sent_from_tablet: checkboxState.value === '1' }));
          dispatch(setOriginalCurrentEquipmentStatus(row));
          setModalOpen(true);
        }}
        showEditModal
        selectItem={handleSelectItem}
        selectedItems={selectedItenIds}
        selectAll={handleSelectAll}
        className={classes.table}
      />
      <Modal
        open={modalOpen}
        onBackdropClick={() => setModalOpen(false)}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div>
          <EquipmentStatusEditModal handleClose={handleClose} />
        </div>
      </Modal>
      <Modal
        open={modalAllOpen}
        onBackdropClick={() => setModalAllOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <EquipmentStatusEditAllModal
            handleClose={handleAllClose}
            selectedItems={selectedItems}
          />
        </div>
      </Modal>
    </div>
  );
};

EquipmentStatusTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EquipmentStatusTab);
