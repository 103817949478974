import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import Footer from '~/pages/Manager/Footer';
import Header from '~/pages/Manager/Header';
import {
  getEquipments,
  getEquipmentsTemplate,
  exportEquipments,
  importEquipments,
  resetFileUploadErrors,
} from '~/store/manager/actions';
import styles from '../styles';

const EquipmentsTab = ({
  classes,
  equipments,
  getEquipments: getAll,
  getEquipmentsTemplate: getTemplateAction,
  exportEquipments: exportAction,
  importEquipments: importAction,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showDialogImport, setShowDialogImport] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 15;
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);

  useEffect(() => {
    async function fetchData() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  useEffect(() => {
    const types = [...new Set(equipments.filter(e => e.type_name).map(e => e.type_name))];
    setEquipmentTypes(types);
  }, [equipments]);

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
    }
  }, [fileUploadErrors]);

  const columns = [
    { field: 'type_name', title: translate('common:Type') },
    { field: 'group_name', title: translate('common:Group') },
    { field: 'name', title: translate('common:Name') },
    { field: 'description', title: translate('common:Description') },
    { field: 'password', title: translate('common:Password') },
    { field: 'weighing_tare', title: translate('common:WeighingTare') },
    { field: 'license_plate', title: translate('common:Plate') },
    { field: 'active_name', title: 'STATUS' },
  ];

  const handleEquipmentTypeChange = (e) => {
    setEquipmentType(e.target.value);
  };

  const renderEquipmentTypes = () => [{ value: '0', label: translate('common:Select') }]
    .concat(...equipmentTypes.map(e => ({ value: e, label: e })));

  return (
    <div className={classes.tabContainer}>
      <div>
        <p className={classes.labelTypeSelector}>
          {translate('common:EquipmentType')}
        </p>
        <PF2MSearchSelectOutlined
          value={(equipmentType || 0).toString()}
          onChange={handleEquipmentTypeChange}
          className={classes.typeSelector}
        >
          {renderEquipmentTypes()}
        </PF2MSearchSelectOutlined>
      </div>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={equipments.filter(e => equipmentType === 0 || e.type_name === equipmentType)}
        columns={columns}
        className={classes.grid}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <Footer
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        size={equipments.filter(e => equipmentType === 0 || e.type_name === equipmentType).length}
      />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
    </div>
  );
};

EquipmentsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  equipments: PropTypes.array,
  getEquipments: PropTypes.func,
  getEquipmentsTemplate: PropTypes.func,
  exportEquipments: PropTypes.func,
  importEquipments: PropTypes.func,
};

EquipmentsTab.defaultProps = {
  equipments: [],
  getEquipments: () => { },
  getEquipmentsTemplate: () => { },
  exportEquipments: () => { },
  importEquipments: () => { },
};

const mapStateToProps = state => ({
  equipments: state.manager.equipments,
});

const mapDispatchToProps = {
  getEquipments,
  getEquipmentsTemplate,
  exportEquipments,
  importEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentsTab));
