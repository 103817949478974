import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Modal, Typography, Fab,
  TextField, Tab, Tabs, AppBar,
  Badge,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MButton from '~/components/PF2MButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import NumberFormat from 'react-number-format';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import {
  updateLoadAllocations,
  updateTruckAllocations,
  getLoadMaterialMappings,
  setSelectedLoadAllocation,
  setSelectedLoadAllocationValues,
  setLoadMaterialMappingsValue,
  deallocateLoadAllocations,
  deallocateTruckAllocations,
  setLoadMaterialMappingsItem,
  clearLoadMaterialMappings,
} from '~/store/dispatch/actions';
import classnames from 'classnames';
import styles from '../../styles';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import { HelpOutline } from '@material-ui/icons';
import PF2MTooltip from '~/components/PF2MTooltip';
import PF2MSwitch from '~/components/PF2MSwitch';
import withModal, { ModalDefaultProps, ModalPropTypes } from '~/components/PF2MModal/withModal';
import { checkModulePermission, handleDynamicClass, handleDynamicIcon } from '~/utils';

const LAM = ({
  isOpen,
  close,
  classes,
  container,
}) => {
  const dispatch = useDispatch();
  const [selectedModalTab, setSelectedModalTab] = useState(0);
  const [currentLoadMaterialMapping, setCurrentLoadMaterialMapping] = useState([]);
  const { t: translate } = useTranslation();
  const loadAllocation = useSelector(state => state.dispatch.selectedLoadAllocation);
  const loadTypes = useSelector(
    state => state.nref.equipmentTypes.filter(r => [1, 15].includes(r.id)),
  );
  const loadGroups = useSelector(
    state => state.nref.equipmentGroups.filter(r => [1, 15].includes(r.id_equipament)),
  );
  const truckGroups = useSelector(
    state => state.nref.equipmentGroups.filter(r => [2].includes(r.id_equipament)),
  );
  const loadEquipments = useSelector(
    state => state.nref.equipments.filter(r => [1, 15].includes(r.id_equip)),
  );
  const truckEquipments = useSelector(
    state => state.nref.equipments.filter(r => [2].includes(r.id_equip)),
  );
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const loadMaterialMappings = useSelector(state => state.dispatch.loadMaterialMappings);
  // eslint-disable-next-line no-underscore-dangle
  const roles = useSelector(state => state.auth.user._roles);

  useEffect(() => {
    if (loadAllocation.equip_id) {
      dispatch(getLoadMaterialMappings({
        equipmentId: loadAllocation.equip_id,
      }));
    }
  }, [loadAllocation.equip_id, dispatch]);

  const handleValueChange = (key, value, index = 0) => {
    const dynamicConfigurationKeys = [
      'group_name',
      'max_allocation_capacity',
      'max_load_queue_time',
      'mean_queue_time_range',
      'min_permanent_allocation_cycles',
      'max_temporary_allocation_cycles',
    ];

    if (dynamicConfigurationKeys.includes(key)) {
      dispatch(setSelectedLoadAllocation({
        ...loadAllocation,
        dynamic_configuration: {
          ...loadAllocation.dynamic_configuration,
          [key]: value,
        },
      }));
      return;
    }

    const newItem = {
      ...loadAllocation,
      [key]: value,
    };
    if (key === 'equip_type_id') {
      newItem.equip_group_id = 0;
      newItem.equip_id = 0;
    }
    if (key === 'equip_group_id') {
      newItem.equip_id = 0;
    }
    if (key === 'equip_id') {
      newItem.materials = loadAllocation.materials;
      dispatch(clearLoadMaterialMappings());
      currentLoadMaterialMapping.forEach((c) => {
        dispatch(setLoadMaterialMappingsItem({
          ...c,
          equip_id: value,
          equip_name: loadEquipments.find(r => r.id === value).name,
          update_status: 'I',
          id: null,
        }));
      });
      setCurrentLoadMaterialMapping([]);
    }
    if (key === 'originElement') {
      newItem.origin_element_point_id = 0;
    }
    if (key === 'materials') {
      newItem.materials = loadAllocation.materials;
      newItem.materials[index] = value;
    }
    dispatch(setSelectedLoadAllocationValues(newItem));
  };

  const handleClose = () => {
    dispatch(setSelectedLoadAllocation(null));
    setCurrentLoadMaterialMapping([]);
    close();
  };

  const renderTabBar = () => (
    <AppBar
      position="static"
      style={{
        backgroundColor: 'white',
        boxShadow: 'none',
      }}
    >
      <div>
        <Tabs
          value={selectedModalTab}
          onChange={(event, value) => setSelectedModalTab(value)}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            label={'Alocação fixa'}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={(
              <Badge
                className={handleDynamicClass({ dynamic: true }, roles, classes)}
                overlap="rectangular"
                badgeContent={handleDynamicIcon({ dynamic: true }, roles)}
              >
                {'Alocação dinâmica'}
              </Badge>
            )}
            disabled={!checkModulePermission(roles, 'DYNAMIC')}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
        </Tabs>
      </div>
    </AppBar>
  );

  const renderHeader = () => (
    <div style={{ marginBottom: 20 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {
          loadAllocation
            ? (<span>{`${translate('common:Allocation')}: ${loadAllocation.equip_name}`}</span>)
            : (<span>{translate('common:NewAllocation')}</span>)
        }
      </Typography>
      <div>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:EquipmentType')}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              value={loadAllocation.equip_type_id}
              onChange={e => handleValueChange('equip_type_id', e.target.value)}
              className={classnames(classes.field)}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...loadTypes
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 10 }}>
          <FormLabel className={classes.formLabel}>
            {translate('common:EquipmentGroup')}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              value={loadAllocation.equip_group_id}
              onChange={e => handleValueChange('equip_group_id', e.target.value)}
              className={classnames(classes.field)}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...loadGroups
                  .filter(r => (loadAllocation.equip_type_id === 0
                    || loadAllocation.equip_type_id === r.id_equipament))
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 10 }}>
          <FormLabel className={classes.formLabel}>
            {translate('common:Equipment')}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              onChange={e => handleValueChange('equip_id', e.target.value)}
              value={loadAllocation.equip_id}
              className={classnames(classes.field)}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...loadEquipments
                  .filter(r => (loadAllocation.equip_group_id === 0
                    || loadAllocation.equip_group_id === r.id_group))
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );

  const renderMaterialRow = (materialId, index) => {
    const foundLoadMaterialMapping = loadMaterialMappings
      .find(r => r.material_id === materialId);

    if (currentLoadMaterialMapping[index] === undefined && foundLoadMaterialMapping) {
      setCurrentLoadMaterialMapping(old => ([...old, foundLoadMaterialMapping]));
    }

    return (
      <>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:Material')}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              value={materialId || 0}
              className={classnames(classes.field)}
              onChange={(e) => {
                handleValueChange('materials', e.target.value, index);
              }}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...loadMaterialMappings
                  .map(e => (
                    { value: e.material_id, label: e.material_name }
                  )))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:DestinationElement')} 1`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              className={classnames(classes.field)}
              value={foundLoadMaterialMapping ? foundLoadMaterialMapping.first_element_id : 0}
              onChange={(e) => {
                if (foundLoadMaterialMapping) {
                  dispatch(setLoadMaterialMappingsValue(
                    foundLoadMaterialMapping, 'first_element_id', e.target.value,
                  ));
                }
              }}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...elements
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:DestinationSubElement')} 1`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              className={classnames(classes.field)}
              value={foundLoadMaterialMapping
                ? foundLoadMaterialMapping.first_element_point_id : 0}
              onChange={(e) => {
                if (foundLoadMaterialMapping) {
                  dispatch(setLoadMaterialMappingsValue(
                    foundLoadMaterialMapping, 'first_element_point_id', e.target.value,
                  ));
                }
              }}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...subElements
                  .filter(r => (
                    !foundLoadMaterialMapping
                    || !foundLoadMaterialMapping.first_element_id
                    || r.id_element === foundLoadMaterialMapping.first_element_id
                  ))
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:DestinationElement')} 2`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              className={classnames(classes.field)}
              value={foundLoadMaterialMapping ? foundLoadMaterialMapping.second_element_id : 0}
              onChange={(e) => {
                if (foundLoadMaterialMapping) {
                  dispatch(setLoadMaterialMappingsValue(
                    foundLoadMaterialMapping, 'second_element_id', e.target.value,
                  ));
                }
              }}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...elements
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:DestinationSubElement')} 2`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              className={classnames(classes.field)}
              value={foundLoadMaterialMapping
                ? foundLoadMaterialMapping.second_element_point_id : 0}
              onChange={(e) => {
                if (foundLoadMaterialMapping) {
                  dispatch(setLoadMaterialMappingsValue(
                    foundLoadMaterialMapping, 'second_element_point_id', e.target.value,
                  ));
                }
              }}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...subElements
                  .filter(r => (
                    !foundLoadMaterialMapping
                    || !foundLoadMaterialMapping.second_element_id
                    || r.id_element === foundLoadMaterialMapping.second_element_id
                  ))
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
      </>
    );
  };

  const renderPermanentAllocationTab = () => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridColumnGap: 10,
      gridRowGap: 10,
    }}
    >
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Element')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            placeholder={null}
            onChange={e => handleValueChange('origin_element_id', e.target.value)}
            value={loadAllocation.origin_element_id}
            className={classnames(classes.field)}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...elements
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:SubElement')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            placeholder={null}
            onChange={e => handleValueChange('origin_element_point_id', e.target.value)}
            value={loadAllocation.origin_element_point_id}
            className={classnames(classes.field)}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...subElements
                .filter(r => (loadAllocation.origin_element_id === 0
                  || loadAllocation.origin_element_id === r.id_element))
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>

      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:TruckEquipmentGroups')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            className={classnames(classes.field)}
            value={loadAllocation.truck_groups}
            onChange={e => handleValueChange('truck_groups', [...e.target.value.map(r => r.value)])}
            multiple
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            placeholder={translate('common:Select')}
          >
            {truckGroups
              .map(r => ({ value: r.id, label: r.name }))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:TruckEquipments')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            className={classnames(classes.field)}
            value={loadAllocation.trucks}
            onChange={e => handleValueChange('trucks', [...e.target.value.map(r => r.value)])}
            multiple
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            placeholder={translate('common:Select')}
          >
            {truckEquipments
              .filter(r => (loadAllocation.truck_groups.length === 0
                || loadAllocation.truck_groups.includes(r.id_group)))
              .map(r => (
                { value: r.id, label: r.name }
              ))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <br />
      {renderMaterialRow(loadAllocation.materials[0], 0)}
      {renderMaterialRow(loadAllocation.materials[1], 1)}
    </div>
  );

  const renderTemporaryAllocationTab = () => (
    <div>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <FormControl>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel className={classes.formLabel}>
              {translate('common:DynamicAllocation')}
              <PF2MTooltip
                title={translate('common:DynamicAllocationExplanation')}
              >
                <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
              </PF2MTooltip>
            </FormLabel>
            <FormGroup>
              <PF2MSwitch
                checked={loadAllocation.dynamic_allocation}
                onChange={() => handleValueChange('dynamic_allocation', !loadAllocation.dynamic_allocation)}
              />
            </FormGroup>
          </div>
        </FormControl>
      </div>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:AllocationGroup')}
            <PF2MTooltip
              title={translate('common:AllocationGroupExplanation')}
            >
              <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
            </PF2MTooltip>
          </FormLabel>
          <FormGroup>
            <PF2MOutlinedInput
              disabled={!loadAllocation.dynamic_allocation}
              value={loadAllocation.dynamic_configuration?.group_name || ''}
              className={classes.field}
              style={{ width: 300 }}
              inputProps={{
                maxLength: 2,
              }}
              onBlur={(e) => {
                handleValueChange('group_name', e.target.value);
              }}
            />
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 10 }}>
          <FormLabel className={classes.formLabel}>
            {translate('common:MaxAllocationCapacity')}
            <PF2MTooltip
              title={translate('common:MaxAllocationCapacityExplanation')}
            >
              <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
            </PF2MTooltip>
          </FormLabel>
          <FormGroup>
            <NumberFormat
              customInput={PF2MOutlinedInput}
              disabled={!loadAllocation.dynamic_allocation}
              value={loadAllocation.dynamic_configuration?.max_allocation_capacity || 0}
              className={classes.field}
              style={{ width: 300 }}
              onBlur={e => handleValueChange('max_allocation_capacity', parseInt(e.target.value || 0, 10))}
              decimalScale={0}
            />
          </FormGroup>
        </FormControl>
      </div>
      <div style={{ marginBottom: 10 }}>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:MaxLoadQueueTime')}
            <PF2MTooltip
              title={translate('common:MaxLoadQueueTimeExplanation')}
            >
              <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
            </PF2MTooltip>
          </FormLabel>
          <FormGroup>
            <NumberFormat
              customInput={PF2MOutlinedInput}
              disabled={!loadAllocation.dynamic_allocation}
              value={loadAllocation.dynamic_configuration?.max_load_queue_time || 0}
              className={classes.field}
              style={{ width: 300 }}
              onBlur={e => handleValueChange('max_load_queue_time', parseFloat(e.target.value || 0))}
              decimalScale={2}
            />
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 10 }}>
          <FormLabel className={classes.formLabel}>
            {translate('common:MeanQueueTimeRange')}
            <PF2MTooltip
              title={translate('common:MeanQueueTimeRangeExplanation')}
            >
              <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
            </PF2MTooltip>
          </FormLabel>
          <FormGroup>
            <NumberFormat
              customInput={PF2MOutlinedInput}
              disabled={!loadAllocation.dynamic_allocation}
              value={loadAllocation.dynamic_configuration?.mean_queue_time_range || 0}
              className={classes.field}
              style={{ width: 300 }}
              onBlur={e => handleValueChange('mean_queue_time_range', parseFloat(e.target.value || 0))}
              decimalScale={2}
            />
          </FormGroup>
        </FormControl>
      </div>
      <div style={{ marginBottom: 10, display: 'none' }}>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {translate('common:MinimumPermanentAllocationCycles')}
            <PF2MTooltip
              title={translate('common:MinPermanentAllocationCyclesExplanation')}
            >
              <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
            </PF2MTooltip>
          </FormLabel>
          <FormGroup>
            <NumberFormat
              customInput={PF2MOutlinedInput}
              disabled={!loadAllocation.dynamic_allocation}
              value={loadAllocation.dynamic_configuration?.min_permanent_allocation_cycles || 0}
              className={classes.field}
              style={{ width: 300 }}
              onBlur={e => handleValueChange('min_permanent_allocation_cycles', parseInt(e.target.value || 0, 10))}
              decimalScale={0}
            />
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 10 }}>
          <FormLabel className={classes.formLabel}>
            {translate('common:MaximumTemporaryAllocationCycles')}
            <PF2MTooltip
              title={translate('common:MaxTemporaryAllocationCyclesExplanation')}
            >
              <HelpOutline fontSize="small" style={{ marginLeft: 5 }} />
            </PF2MTooltip>
          </FormLabel>
          <FormGroup>
            <NumberFormat
              disabled={!loadAllocation.dynamic_allocation}
              customInput={PF2MOutlinedInput}
              value={loadAllocation.dynamic_configuration?.max_temporary_allocation_cycles || 0}
              className={classes.field}
              style={{ width: 300 }}
              onBlur={e => handleValueChange('max_temporary_allocation_cycles', parseInt(e.target.value || 0, 10))}
              decimalScale={0}
            />
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => reason === 'backdropClick' && handleClose()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      container={container || document.body}
    >
      <div style={{ width: 1200 }}>
        <Card>
          <CardHeader icon>
            <>
              <CardIcon color="warning">
                <CreateIcon />
              </CardIcon>
              <CardIcon
                onClick={handleClose}
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  background: 'darkgray',
                  borderRadius: 50,
                  boxShadow: '10px 10px 50px gray',
                }}
              >
                <CloseIcon />
              </CardIcon>
            </>
          </CardHeader>
          <CardBody>
            <div>
              {renderHeader()}
              <div>
                {renderTabBar()}
                <div style={{ padding: 20 }}>
                  {selectedModalTab === 0 && renderPermanentAllocationTab()}
                  {selectedModalTab === 1 && renderTemporaryAllocationTab()}
                </div>
              </div>
              <div style={{
                display: 'flex',
                marginTop: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {!!loadAllocation.id && (
                  <PF2MButton
                    color="primary"
                    onClick={() => {
                      dispatch(deallocateLoadAllocations(loadAllocation.id));
                      handleClose();
                    }}
                  >
                    <DeleteOutline className={classes.icon} />
                    {translate('common:DeleteAllocation')}
                  </PF2MButton>
                )}
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    dispatch(updateLoadAllocations(
                      loadAllocation,
                      loadMaterialMappings
                        .filter(r => loadAllocation.materials.includes(r.material_id)),
                    ));
                    handleClose();
                  }}
                  className={classes.saveButton}
                >
                  <SaveIcon className={classes.icon} />
                  {translate('common:Save')}
                </Fab>
              </div>
            </div>
            <div />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

LAM.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  container: PropTypes.element,
};

LAM.defaultProps = {
  isOpen: false,
  close: () => null,
  container: null,
};

export const LoadAllocationModal = withStyles(styles)(LAM);


const TruckllocationModal = React.forwardRef(({ closeModal, classes }, _ref) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const loadAllocations = useSelector(
    state => state.dispatch.loadAllocations.filter(r => r.priority),
  );
  const selectedTruckAllocation = useSelector(state => state.dispatch.selectedTruckAllocation);
  const [selectedLoadAllocationId, setSelectedLoadAllocationId] = useState(
    selectedTruckAllocation?.load_allocation_id || 0,
  );

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:EquipmentAllocation')}: ${selectedTruckAllocation.equip_name}`}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedTruckAllocation.equip_type_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedTruckAllocation.equip_group_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Equipment')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedTruckAllocation.equip_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Status')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedTruckAllocation.status}
          </span>
        </div>
      </div>
    </div>
  );

  const onSave = () => {
    dispatch(updateTruckAllocations(
      selectedTruckAllocation, 'load_allocation_id', selectedLoadAllocationId, true, true,
    ));
    closeModal();
  };
  return (
    <div style={{ width: 600 }}>
      <Card>
        <CardHeader icon>
          <>
            <CardIcon color="warning">
              <CreateIcon />
            </CardIcon>
            <CardIcon
              onClick={closeModal}
              style={{
                float: 'right',
                cursor: 'pointer',
                background: 'darkgray',
                borderRadius: 50,
                boxShadow: '10px 10px 50px gray',
              }}
            >
              <CloseIcon />
            </CardIcon>
          </>
        </CardHeader>
        <CardBody>
          <div />
          <div>
            {renderHeader()}
            <hr />
            <div>
              <div style={{
                display: 'flex',
                marginTop: 10,
                width: '100%',
              }}
              >
                <FormControl>
                  <FormLabel className={classes.formLabel}>
                    {translate('common:AllocateTo')}
                  </FormLabel>
                  <FormGroup>
                    <PF2MSearchSelectOutlined
                      placeholder={null}
                      className={classnames(classes.field)}
                      value={selectedLoadAllocationId}
                      onChange={e => setSelectedLoadAllocationId(e.target.value)}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(loadAllocations
                          .map(e => ({ value: e.id, label: e.equip_name })))}
                    </PF2MSearchSelectOutlined>
                  </FormGroup>
                </FormControl>
              </div>
              <div style={{
                display: 'flex',
                marginTop: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {!!selectedTruckAllocation.id && (
                  <PF2MButton
                    color="primary"
                    onClick={() => {
                      dispatch(deallocateTruckAllocations(selectedTruckAllocation.id));
                      closeModal();
                    }}
                  >
                    <DeleteOutline className={classes.icon} />
                    {translate('common:DeleteAllocation')}
                  </PF2MButton>
                )}
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  onClick={onSave}
                  className={classes.saveButton}
                >
                  <SaveIcon className={classes.icon} />
                  {translate('common:Save')}
                </Fab>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
});

TruckllocationModal.propTypes = {
  ...ModalPropTypes,
};

TruckllocationModal.defaultProps = {
  ...ModalDefaultProps,
};

export const TruckAllocationModal = withModal(withStyles(styles)(TruckllocationModal));

const allocationModes = {
  STANDARD: 'standard',
  TEMPORARY: 'temp',
};

const TruckAllocationModeModalWithoutStyles = ({
  isOpen,
  close,
  classes,
  refreshOrigins,
  container,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const pendingTruckAllocationUpdate = useSelector(
    state => state.dispatch.pendingTruckAllocationUpdate,
  );

  const [radioValue, setRadioValue] = useState(allocationModes.STANDARD);
  const [tempFieldValue, setTempFieldValue] = useState(5);

  const handleClose = () => {
    close();
  };

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:AllocationMode')}`}
      </Typography>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => reason === 'backdropClick' && handleClose()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      container={container || document.body}
    >
      <div style={{ width: 400 }}>
        <Card>
          <CardHeader icon>
            <>
              <CardIcon
                onClick={handleClose}
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  background: 'darkgray',
                  borderRadius: 50,
                  boxShadow: '10px 10px 50px gray',
                }}
              >
                <CloseIcon />
              </CardIcon>
            </>
          </CardHeader>
          <CardBody>
            <div />
            <div>
              {renderHeader()}
              <hr />
              <RadioGroup
                name="allocationmode"
                value={radioValue}
                onChange={event => setRadioValue(event.target.value)}
                style={{ padding: 10 }}
              >
                <FormControlLabel value={allocationModes.STANDARD} control={<Radio />} label={translate('common:Standard')} />
                <FormControlLabel value={allocationModes.TEMPORARY} control={<Radio />} label={translate('common:Temporary')} />
              </RadioGroup>
              {radioValue === allocationModes.TEMPORARY && (
                <div style={{ display: 'flex' }}>
                  <NumberFormat
                    customInput={TextField}
                    value={tempFieldValue}
                    InputProps={{ classes: { input: classes.input } }}
                    onChange={e => setTempFieldValue(e.target.value)}
                    style={{ width: 70, paddingLeft: 10 }}
                    disabled={radioValue === allocationModes.STANDARD}
                    decimalScale={0}
                  />
                  <Typography
                    style={{
                      marginLeft: 5, display: 'flex', alignItems: 'flex-end', textTransform: 'lowercase',
                    }}
                  >
                    {translate('common:Cycles')}
                  </Typography>
                </div>
              )}
            </div>
            <div style={{
              display: 'flex',
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Fab
                variant="extended"
                size="medium"
                color="secondary"
                onClick={() => {
                  const newTruckAllocation = {
                    ...pendingTruckAllocationUpdate,
                    cycles: (radioValue === allocationModes.TEMPORARY
                      ? parseInt(tempFieldValue || 0, 10) : null),
                  };

                  dispatch(updateTruckAllocations(
                    newTruckAllocation,
                    'load_allocation_id', newTruckAllocation.load_allocation_id, refreshOrigins,
                  ));

                  close();
                }}
                className={classes.saveButton}
              >
                {translate('common:Confirm')}
              </Fab>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

TruckAllocationModeModalWithoutStyles.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  refreshOrigins: PropTypes.bool,
  container: PropTypes.element,
};

TruckAllocationModeModalWithoutStyles.defaultProps = {
  isOpen: false,
  close: () => null,
  refreshOrigins: false,
  container: null,
};

export const TruckAllocationModeModal = withStyles(styles)(TruckAllocationModeModalWithoutStyles);
