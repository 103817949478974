import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { useTranslation } from 'react-i18next';
import {
  getMaterialsGroupsTemplate,
  exportMaterialsGroups,
  importMaterialsGroups,
  getMaterialsGroups,
  resetFileUploadErrors,
} from '~/store/manager/actions';

const GroupsTab = ({
  classes,
  materialsGroups,
  getMaterialsGroupsTemplate: getTemplateAction,
  exportMaterialsGroups: exportAction,
  importMaterialsGroups: importAction,
  getMaterialsGroups: getAll,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showDialogImport, setShowDialogImport] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchOperators();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
    }
  }, [fileUploadErrors]);

  const columns = [
    // {
    //   field: 'type_name', title: 'TIPO',
    // },
    {
      field: 'name', title: translate('common:Name'),
    },
    {
      field: 'description', title: translate('common:Description'), width: '100%',
    },
    {
      field: 'active_name', title: 'STATUS',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={materialsGroups}
        columns={columns}
      />
      <Footer />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
    </div>
  );
};

GroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  materialsGroups: PropTypes.array,
  getMaterialsGroupsTemplate: PropTypes.func,
  exportMaterialsGroups: PropTypes.func,
  importMaterialsGroups: PropTypes.func,
  getMaterialsGroups: PropTypes.func,
};

GroupsTab.defaultProps = {
  materialsGroups: [],
  getMaterialsGroupsTemplate: () => {},
  exportMaterialsGroups: () => {},
  importMaterialsGroups: () => {},
  getMaterialsGroups: () => {},
};

const mapStateToProps = state => ({
  materialsGroups: state.manager.materialsGroups,
});

const mapDispatchToProps = {
  getMaterialsGroupsTemplate,
  exportMaterialsGroups,
  importMaterialsGroups,
  getMaterialsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsTab));
