import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';
import SubElementsFilter from './SubElementsFilter';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

import {
  getSubElementsTemplate,
  exportSubElements,
  importSubElements,
  getSubElements,
  setSubElementValue,
  getSubElementTypes,
  updateSubElements,
  resetSubElements,
  resetFileUploadErrors,
} from '~/store/manager/actions';

const SubElementsTab = ({
  classes,
  subElements,
  subElementTypes,
  getSubElementsTemplate: getTemplateAction,
  exportSubElements: exportAction,
  importSubElements: importAction,
  getSubElements: getAll,
  isSubElementsDirty,
  updateSubElements: update,
  resetSubElements: reset,
  setSubElementValue: updateItem,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(true);
  const [currentFilter, setCurrentFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [showDialogImport, setShowDialogImport] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);

  const itemsPerPage = 15;

  useEffect(() => {
    async function fetchData() {
      await getAll(false, currentFilter);
      dispatch(getSubElementTypes(true));
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, currentFilter, dispatch]);

  useEffect(() => {
    if (Object.keys(currentFilter).length > 0) {
      setDataLoaded(false);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
    }
  }, [fileUploadErrors]);

  const columns = [
    {
      field: 'element_name',
      title: translate('common:Element'),
    },
    {
      field: 'name',
      title: translate('common:SubElement'),
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'sub_element_type_id',
      title: translate('common:Type'),
      editable: true,
      selectItems: subElementTypes,
    },
    {
      field: 'active', title: 'STATUS', editable: true,
    },
    {
      field: 'polygon_count',
      title: translate('common:Polygons'),
      width: '100%',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <SubElementsFilter setCurrentFilter={setCurrentFilter} />
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={() => exportAction(currentFilter)}
        currentFilter={currentFilter}
      />
      <PF2MTable
        customStyles={{ height: 'calc(100vh - 52px - 52px - 180px - 103px)' }}
        data={subElements.sort((a, b) => (a.id < b.id ? 1 : -1))}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        columns={columns}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isSubElementsDirty}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        size={subElements.length}
        discard={() => reset()}
        sendData={() => update(subElements, currentFilter)}
        style={{ marginTop: 10 }}
      />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
    </div>
  );
};

SubElementsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  subElements: PropTypes.array,
  subElementTypes: PropTypes.array,
  getSubElementsTemplate: PropTypes.func,
  exportSubElements: PropTypes.func,
  importSubElements: PropTypes.func,
  getSubElements: PropTypes.func,
  isSubElementsDirty: PropTypes.bool,
  updateSubElements: PropTypes.func,
  resetSubElements: PropTypes.func,
  setSubElementValue: PropTypes.func,
};

SubElementsTab.defaultProps = {
  subElements: [],
  subElementTypes: [],
  getSubElementsTemplate: () => { },
  exportSubElements: () => { },
  importSubElements: () => { },
  getSubElements: () => { },
  isSubElementsDirty: false,
  updateSubElements: null,
  resetSubElements: null,
  setSubElementValue: null,
};

const mapStateToProps = state => ({
  subElements: state.manager.subElements,
  isSubElementsDirty: state.manager.isSubElementsDirty,
  subElementTypes: state.manager.subElementTypes,
});

const mapDispatchToProps = {
  getSubElementsTemplate,
  exportSubElements,
  importSubElements,
  getSubElements,
  updateSubElements,
  resetSubElements,
  setSubElementValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubElementsTab));
