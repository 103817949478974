// Mine Structure
export const SET_NREF_ELEMENTS = 'SET_NREF_ELEMENTS';
export const SET_NREF_SUBELEMENTS = 'SET_NREF_SUBELEMENTS';

export const SET_NREF_CODES = 'SET_NREF_CODES';
export const SET_NREF_CODE_GROUPS = 'SET_NREF_CODE_GROUPS';
export const SET_NREF_CODE_TYPES = 'SET_NREF_CODE_TYPES';

export const SET_NREF_OPERATORS = 'SET_NREF_OPERATORS';
export const SET_NREF_OPERATORS_GROUPS = 'SET_NREF_OPERATORS_GROUPS';
export const SET_NREF_MATERIALS = 'SET_NREF_MATERIALS';
export const SET_NREF_EQUIPMENT_GROUPS = 'SET_NREF_EQUIPMENT_GROUPS';
export const SET_NREF_EQUIPMENT_TYPES = 'SET_NREF_EQUIPMENT_TYPES';
export const SET_NREF_EQUIPMENTS = 'SET_NREF_EQUIPMENTS';
