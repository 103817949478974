import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PF2MDataTable, { icons } from '~/components/PF2MDataTable';
import useUploadFile from '~/components/PF2MDataTable/uploadFile';
import {
  addDispatchOperationalControl,
  deleteOperationalControlValues,
  importDispatchOperationalControl,
  getDispatchOperationalControl,
  getTemplateDispatchOperationalControl,
  restoreOperationalControl,
  setOperationalControlValues,
  updateDispatchOperationalControl,
  clearCurrentOperationalControlFilter,
} from '~/store/dispatch/actions';
import {
  getEquipments,
  getEquipmentGroups,
  getElements,
  getSubElements,
  getMaterials,
} from '~/store/nref/actions';
import {
  getOperators,
  getOperatorsGroups,
  getExceptionTypes,
  getTurns,
  getTeams,
  getTeamsListing,
} from '~/store/manager/actions';
import { formatDate, formatTimestamp, newMomentDate } from '~/utils/moment';
import DispatchOperationControlModalFilter from './DispatchOperationControlModalFilter';

const validateDropDowns = (value, noneValue = '0') => value && value !== noneValue;
const validateNumbers = value => !Number.isNaN(parseInt(value, 10));
const formatOperationalControl = opCtrl => ({
  ...opCtrl,
  timestamp_op: opCtrl.timestamp_op * 1000,
  total_load: opCtrl.cycles_number * opCtrl.load_default,
});
const unFormatOperationalControl = opCtrl => ({
  ...opCtrl,
  timestamp_op: opCtrl.timestamp_op / 1000,
});
const validateDates = _row => (_row.timestamp_op ? true : 'Campo obrigatório');
const VALID_EQUIPMENT_TYPES = [2];
const dropDownDefault = value => ((value === '0' || !value) ? undefined : value);

function OperationalControl() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
  const currentFilter = useSelector(state => state.dispatch.currentOperationalFilter);

  useEffect(() => {
    dispatch(getSubElements());
    dispatch(getElements());
    dispatch(getTurns());
    dispatch(getTeams());
    dispatch(getMaterials());
    dispatch(getOperators());
    dispatch(getOperatorsGroups());
    dispatch(getEquipments());
    dispatch(getEquipmentGroups());
    dispatch(getExceptionTypes());
    dispatch(getTeamsListing(0, 0, true));
  }, [dispatch]);

  useEffect(() => {
    const filters = { ...currentFilter };
    filters.start_date = formatDate(currentFilter.start_date, true);
    filters.end_date = formatDate(currentFilter.end_date, true);
    dispatch(getDispatchOperationalControl(filters));
  }, [dispatch, currentFilter]);

  const operationalControl = useSelector(s => s.dispatch.operationalControl
    .map(formatOperationalControl), shallowEqual);
  const subElements = useSelector(s => s.nref.subElements);
  const elements = useSelector(s => s.nref.elements);
  const materials = useSelector(s => s.nref.materials);
  const operators = useSelector(s => s.manager.operators);
  const operatorGroups = useSelector(s => s.manager.operatorsGroups);
  const equipments = useSelector(s => s.nref.equipments);
  const equipmentGroups = useSelector(s => s.nref.equipmentGroups
    .filter(equipGroup => VALID_EQUIPMENT_TYPES.includes(equipGroup.id_equipament)), shallowEqual);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const teamsListing = useSelector(state => state.manager.teamsListing);

  const isTeamGroupListedInTurn = (teamGroupId, turnGroupId) => teamsListing.some(
    team => team.id_turn_group === turnGroupId
      && team.id_team_group === teamGroupId,
  );

  const [input, choseFile] = useUploadFile({
    onFileChange: (selectedFile) => {
      dispatch(importDispatchOperationalControl(selectedFile, currentFilter));
    },
  });

  const equipmentGroupIdsSet = useMemo(
    () => new Set(equipmentGroups.map(eG => String(eG.id))),
    [equipmentGroups],
  );
  const turnsWithGroups = useMemo(
    () => turns.map(t => ({ ...t, name: `${t.turn_group_name} - ${t.name}` })),
    [turns],
  );
  const teamsWithGroups = useMemo(
    () => teams.map(t => ({ ...t, name: `${t.team_group_name} - ${t.name}` })),
    [teams],
  );

  const columns = [
    {
      title: translate('common:StartDate'),
      field: 'timestamp_op',
      customType: 'dateByTimestamp',
      validate: validateDates,
    },
    {
      title: translate('common:Shift'),
      field: 'turn_id',
      lookup: turnsWithGroups,
      validate: row => validateDropDowns(row.turn_id),
    },
    {
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teamsWithGroups,
      customType: 'filterLookup',
      lookupFilter: (option, row) => isTeamGroupListedInTurn(option.id_team_group,
        turns.find(t => String(t.id) === String(row.turn_id))?.id_turn_group),
      validate: row => validateDropDowns(row.team_id),
    },
    {
      title: translate('common:OriginElement'),
      field: 'origin_id',
      lookup: elements,
      customOnChange: () => ({ origin_point_id: '0' }),
      validate: row => validateDropDowns(row.origin_id),
    },
    {
      title: translate('common:SubOriginElement'),
      field: 'origin_point_id',
      lookup: subElements,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(row.origin_id) === '0'
        || String(row.origin_id) === String(option.id_element),
      validate: row => validateDropDowns(row.origin_point_id),
    },
    {
      title: translate('common:DestinationElement'),
      field: 'destination_id',
      lookup: elements,
      customOnChange: () => ({ destination_point_id: '0' }),
      validate: row => validateDropDowns(row.destination_id),
    },
    {
      title: translate('common:SubDestinationElement'),
      field: 'destination_point_id',
      lookup: subElements,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(row.destination_id) === '0'
        || String(row.destination_id) === String(option.id_element),
      validate: row => validateDropDowns(row.destination_point_id),
    },
    {
      title: translate('common:Material'),
      field: 'material_id',
      lookup: materials,
    },
    {
      title: translate('common:TruckEquipmentGroups'),
      field: 'equipment_group_id',
      lookup: equipmentGroups,
      customOnChange: () => ({ equip_id: '0' }),
    },
    {
      title: translate('common:TruckEquipments'),
      field: 'equip_id',
      customType: 'filterLookup',
      lookup: equipments,
      lookupFilter: (e, f) => String(e.id_group) === String(f.equipment_group_id)
        || (String(f.equipment_group_id) === '0' && equipmentGroupIdsSet.has(String(e.id_group))),
    },
    {
      title: translate('common:CyclesNumber'),
      field: 'cycles_number',
      validate: row => validateNumbers(row.cycles_number) && parseInt(row.cycles_number, 10) > 0,
      customOnChange: ({ row, value }) => ({ total_load: row.load_default * value }),
    },
    {
      title: translate('common:AverageLoad'),
      field: 'load_default',
      validate: row => validateNumbers(row.load_default) && parseInt(row.load_default, 10) > 0,
      customOnChange: ({ row, value }) => ({ total_load: row.cycles_number * value }),
    },
    {
      title: translate('common:TotalLoad'),
      field: 'total_load',
      validate: row => validateNumbers(row.load_default) && parseInt(row.load_default, 10) > 0,
      render: row => <div>{row.cycles_number * row.load_default}</div>,
      customOnChange: ({ row, value }) => ({ load_default: value / row.cycles_number }),
    },
    {
      title: translate('common:OperatorGroup'),
      field: 'operator_group_id',
      lookup: operatorGroups,
      customOnChange: () => ({ operator_id: '0' }),
    },
    {
      title: translate('common:Operator'),
      field: 'operator_id',
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookup: operators,
      lookupFilter: (option, row) => String(option.id_group) === String(row.operator_group_id)
        || String(row.operator_group_id) === '0',
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
    },
  ];

  return (
    <>
      {input}
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{ showTitle: true, search: false, selection: true }}
        extraActions={[
          {
            icon: icons.Filter,
            onClick: () => setIsModalFilterOpen(true),
            isFreeAction: true,
            tooltip: translate('common:Filter'),
          },
          {
            icon: icons.FilterOff,
            onClick: () => dispatch(clearCurrentOperationalControlFilter({})),
            isFreeAction: true,
            tooltip: translate('common:ClearFilters'),
          },
        ]}
        onExport={() => dispatch(getTemplateDispatchOperationalControl())}
        onImport={() => choseFile()}
        onAdd={newData => dispatch(addDispatchOperationalControl(newData))}
        onDiscard={() => dispatch(restoreOperationalControl())}
        onApply={() => dispatch(updateDispatchOperationalControl())}
        onChange={(newData) => {
          newData
            .map(unFormatOperationalControl)
            .forEach((row) => {
              dispatch(setOperationalControlValues({
                ...row,
                material_id: dropDownDefault(row.material_id),
                operator_id: dropDownDefault(row.operator_id),
                equip_id: dropDownDefault(row.equip_id),
              }));
            });
        }}
        onDelete={(newData) => {
          newData
            .map(unFormatOperationalControl)
            .forEach((row) => {
              dispatch(deleteOperationalControlValues(row));
            });
        }}
        initialFormData={
          {
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
            timestamp_op: formatTimestamp(newMomentDate()),
          }
        }
        columns={columns}
        data={operationalControl}
      />
      <DispatchOperationControlModalFilter
        modalData={isModalFilterOpen}
        closeModal={() => setIsModalFilterOpen(false)}
        equipmentOptionsFilter={equipment => equipmentGroupIdsSet.has(String(equipment.id_group))}
      />
    </>
  );
}

export default OperationalControl;
