import React, { useState } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Fab, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { updateEquipmentStatus } from '~/store/dispatch/actions';
import { Field, Form, Formik } from 'formik';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import moment from 'moment';
import { newMomentDate, parseDateTimeToTimestamp, verifyTime } from '~/utils/moment';
import TimeCell from '~/components/PF2MDataTable/Cells/TimeCell';

const FormControlDefaults = ({
  formLabel,
  children,
  classes,
  isRequired,
}) => (
  <FormControl className={classes.modelParent} required={isRequired}>
    <FormLabel className={classes.formLabel}>
      {formLabel}
    </FormLabel>
    <FormGroup>
      {children}
    </FormGroup>
  </FormControl>
);

FormControlDefaults.propTypes = {
  classes: PropTypes.object.isRequired,
  formLabel: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
};

FormControlDefaults.defaultProps = {
  isRequired: false,
};

const EquipmentStatusEditAllModal = ({
  classes, selectedItems, handleClose,
}) => {
  // os campos iniciais precisam ser carregados junto com o componente.
  const INIT_SELECTED_FIELDS = {
    id_code_group: 0,
    code_type: -1,
    id_code: 0,
    start_date: newMomentDate(),
    start_time: moment(new Date()).format('HH:mm:ss'),
  };

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const [isModalOpen, setModalOpen] = useState(false);
  const codeTypes = useSelector(state => state.nref.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.nref.codeGroups);
  const codes = useSelector(state => state.nref.codes);
  const [selectedFields, setSelectedFields] = useState(INIT_SELECTED_FIELDS);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentStatusFilter);
  const [formikErrors, setFormikErrors] = useState({});

  const filterCodeGroups = u => (currentFilter.equip_type === u.id_equip)
    && (selectedFields.code_type === -1 || u.code_type === selectedFields.code_type);

  const handleUpdateItems = () => {
    const selectedEquipmentStatuses = equipmentStatuses.filter(
      e => selectedItems.find(sel => sel.id === e.id),
    );

    const timestampToDate = timestamp => moment.unix(timestamp / 1000).format('DD/MM/YYYY');

    const newSelectedItems = selectedEquipmentStatuses.map((i) => {
      const startDate = (
        selectedFields.start_date
          ? timestampToDate(selectedFields.start_date)
          : i.start_date
      );

      const startTime = (
        selectedFields.start_time
          ? selectedFields.start_time
          : i.start_time
      );

      const startTimestamp = parseDateTimeToTimestamp(startDate, startTime) / 1000;

      return {
        ...i,
        start_date: startDate,
        start_time: startTime,
        start_timestamp: startTimestamp,
        code_type: selectedFields.code_type !== -1 ? selectedFields.code_type : i.code_type,
        id_code_group: selectedFields.id_code_group !== -1
          ? selectedFields.id_code_group : i.id_code_group,
        id_code: selectedFields.id_code ? selectedFields.id_code : i.id_code,
      };
    });

    return dispatch(updateEquipmentStatus(newSelectedItems));
  };

  const filterCodes = c => selectedFields.code_type === c.code_type
    && (selectedFields.id_code_group === 0 || c.id_group === selectedFields.id_code_group)
    && (currentFilter.equip_type === c.id_equip);


  const notRequiredFields = [
    'start_date',
    'start_time',
  ];

  // só pode salvar a alteração caso todos os campos sejam preenchidos.
  const disableSaveButton = Object.keys(selectedFields)
    .filter(k => (
      selectedFields[k] === INIT_SELECTED_FIELDS[k]
      && !notRequiredFields.includes(k)
    )).length;

  const modalHandleChange = (field, value) => {
    setSelectedFields((prevSelectedFields) => {
      switch (field) {
        case 'code_type':
          return {
            ...prevSelectedFields, id_code_group: 0, id_code: 0, code_type: value,
          };
        case 'id_code_group':
          return { ...prevSelectedFields, id_code: 0, id_code_group: value };
        default:
          return { ...prevSelectedFields, [field]: value };
      }
    });
  };

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:EditEntries')}`}
      </Typography>
    </div>
  );

  const renderSelectField = ({
    fieldName,
    formLabel,
    collection,
  }) => {
    const defaultSelectValues = [
      {
        value: INIT_SELECTED_FIELDS[fieldName],
        label: translate('common:Select'),
      },
    ];

    const normalizedCollection = defaultSelectValues.concat(
      ...collection.map(e => ({ value: e.id, label: e.name })),
    );

    return (
      <FormControlDefaults formLabel={formLabel} classes={classes} isRequired>
        <Field
          name={fieldName}
          render={({ field, form }) => (
            <PF2MSearchSelectOutlined
              {...field}
              placeholder={null}
              className={classnames('form-control', classes.field)}
              onChange={(e) => {
                form.setFieldValue(fieldName, e.target.value);
                modalHandleChange(fieldName, e.target.value);
              }}
            >
              {normalizedCollection}
            </PF2MSearchSelectOutlined>
          )}
        />
      </FormControlDefaults>
    );
  };

  const renderDateField = ({ fieldName, formLabel }) => (
    <FormControlDefaults formLabel={formLabel} classes={classes}>
      <Field
        name={fieldName}
        render={({ field, form }) => (
          <PF2MDatePickerOutlined
            field={field}
            form={form}
            onChange={(e) => {
              form.setFieldValue(fieldName, e, true);
              modalHandleChange(fieldName, e);
            }}
          />
        )}
      />
    </FormControlDefaults>
  );

  const renderTimeField = ({
    fieldName,
    formLabel,
    values,
    errors,
  }) => (
    <FormControlDefaults formLabel={formLabel} classes={classes}>
      <Field
        name={fieldName}
        render={({ field, form }) => (
          <TimeCell
            {...field}
            error={errors[fieldName]}
            helperText={errors[fieldName]}
            value={values[fieldName]}
            bulkEdit={(e) => {
              form.setFieldValue(fieldName, e.target.value);
              modalHandleChange(fieldName, e.target.value);
            }}
          />
        )}
      />
    </FormControlDefaults>
  );

  const renderFormControl = () => (
    <Formik
      initialValues={INIT_SELECTED_FIELDS}
      validateOnChange
      validate={({ start_time: st, start_date: sd }) => {
        const errors = {};
        const startDate = typeof sd === 'number' ? moment.unix(sd) : sd;

        const currentMoment = newMomentDate();
        const dateTimeString = `${startDate.format('DD/MM/YYYY')} ${st}`;
        const dateTimeMoment = moment(dateTimeString, 'DD/MM/YYYY HH:mm:ss', true);

        if (!verifyTime(st)) {
          errors.start_time = translate('validation:InvalidHour');
        } else if (dateTimeMoment.isAfter(currentMoment)) {
          errors.start_time = translate('validation:FutureTimeCantBeSelected');
        }

        if (!startDate.isValid()) {
          errors.start_date = translate('validation:InvalidDate');
        }

        if (dateTimeMoment.startOf('day').isAfter(currentMoment.startOf('day'))) {
          errors.start_date = translate('validation:FutureDateCantBeSelected');
        }

        setFormikErrors(errors);
        return errors;
      }}
    >
      {({ values, errors }) => (
        <Form
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: 25,
            gridRowGap: 10,
          }}
        >
          {renderDateField({
            fieldName: 'start_date',
            formLabel: translate('common:Date'),
          })}
          {renderTimeField({
            values,
            errors,
            fieldName: 'start_time',
            formLabel: translate('commom:Time'),
          })}
          <br />
          {renderSelectField({
            fieldName: 'code_type',
            formLabel: translate('common:CodeType'),
            collection: codeTypes,
          })}
          {renderSelectField({
            fieldName: 'id_code_group',
            formLabel: translate('common:CodeGroup'),
            collection: codeGroups.filter(filterCodeGroups),
          })}
          {renderSelectField({
            fieldName: 'id_code',
            formLabel: translate('common:Code'),
            collection: codes.filter(filterCodes),
          })}
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          <div>
            {renderFormControl()}
          </div>
          <div style={{
            display: 'flex',
            marginTop: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <PF2MButton
              color="primary"
              onClick={() => setSelectedFields(INIT_SELECTED_FIELDS)}
            >
              <DeleteOutline className={classes.icon} />
              {translate('common:DiscardChanges')}
            </PF2MButton>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              onClick={() => {
                handleUpdateItems();
                handleClose();
              }}
              className={classes.saveButton}
              disabled={
                Boolean(disableSaveButton)
                || Boolean(Object.keys(formikErrors).length)
              }
            >
              {translate('common:SaveData')}
            </Fab>
          </div>
        </CardBody>
      </Card>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:ThereAreBlankFields')}
        open={isModalOpen}
        onConfirm={() => setModalOpen(false)}
      />
    </div>
  );
};

EquipmentStatusEditAllModal.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedItems: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
};

EquipmentStatusEditAllModal.defaultProps = {
  selectedItems: [],
};

export default withStyles(styles)(EquipmentStatusEditAllModal);
