import React, { useState, useRef } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CleanFilterIcon from '~/assets/clean_filter.svg';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { setCurrentOperationControlFilter } from '~/store/underground/actions';
import PF2MCheckboxSelect from '~/components/PF2MCheckboxSelect';
import { withModal } from '~/components/PF2MModal';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import {
  Card, CardHeader, CardBody, CardIcon,
} from '~/components/Card';
import styles from '../styles';
import PF2MSearchButton from '~/components/PF2MSearchButton';

const CleanFilters = ({ onClick, label, className }) => (
  <FormControl>
    <FormGroup>
      <PF2MButton
        color="primary"
        onClick={onClick}
      >
        <img src={CleanFilterIcon} className={className} alt="" />
        {label}
      </PF2MButton>
    </FormGroup>
  </FormControl>
);

CleanFilters.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

const useFilters = () => {
  const currentFilter = useSelector(state => state.underground.currentOperationControlFilter);
  const { current: initValue } = useRef({
    priority: -1,
    id_project_group: 0,
    id_code_type_ug: 0,
    id_code_group_ug: 0,
    id_code_ug: 0,
    id_unitary_operation_group: 0,
    id_unitary_operation: 0,
    id_element: 0,
    id_element_point: 0,
    show_all: false,
    ...currentFilter, // maintain filters on re-filtering
  });
  const [filters, setFilters] = useState(initValue);
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(setCurrentOperationControlFilter(filters));
  };

  const changeFilter = (field, value) => setFilters((oldFilters) => {
    const newFilters = { [field]: value };
    if (field === 'id_element') newFilters.id_element_point = initValue.id_element_point;
    if (field === 'id_code_type_ug') {
      newFilters.id_code_group_ug = initValue.id_code_group_ug;
      newFilters.id_code_ug = initValue.id_code_ug;
    }
    if (field === 'id_code_group_ug') {
      newFilters.id_code_ug = initValue.id_code_ug;
    }
    if (field === 'id_unitary_operation_group') {
      newFilters.id_unitary_operation = initValue.id_unitary_operation;
    }
    return { ...oldFilters, ...newFilters };
  });

  const restoreFilters = () => {
    const initObjectValues = {
      priority: -1,
      id_project_group: 0,
      id_code_type_ug: 0,
      id_code_group_ug: 0,
      id_code_ug: 0,
      id_unitary_operation_group: 0,
      id_unitary_operation: 0,
      id_element: 0,
      id_element_point: 0,
      show_all: false,
    };
    setFilters(initObjectValues);
  };

  return [filters, changeFilter, onSubmit, restoreFilters];
};

const OperationControlFilter = React.forwardRef(({ closeModal, classes }, _ref) => {
  const onlyPriorities = items => [...new Set(items.map(i => i.priority))]
    .map(i => ({ id: i, name: i }));

  const priorities = useSelector(state => onlyPriorities(state.underground.priorities));

  const projectGroups = useSelector(state => state.underground.projectGroups);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const unitOperationGroups = useSelector(state => state.underground.unitOperationGroups);
  const unitOperations = useSelector(state => state.underground.unitOperations
    .sort((a, b) => a.order - b.order));
  const codeTypes = useSelector(state => state.underground.codeTypes);
  const codeGroups = useSelector(state => state.underground.codeGroups);
  const codes = useSelector(state => state.underground.codes);

  const { t: translate } = useTranslation();

  const [filters, handleChange, onSubmit, restoreFilters] = useFilters();

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
  ) => (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          {...classes.field}
          className={classnames(classes.field)}
          placeholder={null}
          value={item[key]}
          onChange={e => handleChange(key, e.target.value)}
        >
          {[{ value: initialValue, label: translate('common:All') }]
            .concat(...collection
              .map(e => ({ value: e.id, label: e.name })))}
        </PF2MSearchSelectOutlined>

      </FormGroup>
    </FormControl>
  );

  const renderCheckbox = (key, label) => (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={(
            <Checkbox
              checked={filters[key]}
              onChange={e => handleChange(key, e.target.checked)}
            />
          )}
          label={(
            <Typography style={styles.checkboxLabel}>
              {label}
            </Typography>
          )}
        />

      </FormGroup>
    </FormControl>
  );
  const filteredSubElements = subElements.filter(item => filters.id_element === 0
    || item.id_element === filters.id_element);


  const filterElementCheckBox = (checkeds, item) => {
    if (!item.active) {
      return false;
    }
    if (!checkeds.is_origin && !checkeds.is_destination) {
      return true;
    }
    if (item.is_origin === checkeds.is_origin && checkeds.is_origin && !item.is_destination) {
      return true;
    }
    if (item.is_destination === checkeds.is_destination
        && checkeds.is_destination && !item.is_origin) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Card style={{ height: '100%' }}>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
          <CardIcon
            onClick={closeModal}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: 'darkgray',
              borderRadius: 50,
              boxShadow: '10px 10px 50px gray',
            }}
          >
            <CloseIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <div className="mb-2">
            <Typography
              variant="h4"
              align="center"
              gutterBottom
            >
              {translate('common:Filters')}
            </Typography>
          </div>
          <div
            className="container"
          >
            <div className="row justify-content-center mt-2">
              <div className="col-6 mb-2">
                {renderFormControl(filters, 'priority', translate('common:Priority'), priorities, -1)}
              </div>
              <div className="col-6 mb-2">

                {renderFormControl(
                  filters, 'id_project_group', translate('common:ProjectGroup'), projectGroups,
                )}
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-6 mb-2">
                {renderFormControl(filters, 'id_code_type_ug', translate('common:CodeType'), codeTypes)}
              </div>
              <div className="col-6 mb-2">
                {renderFormControl(filters, 'id_code_group_ug', translate('common:CodeGroup'), codeGroups
                  .filter(c => filters.id_code_type_ug === 0
                  || c.id_code_type_ug === filters.id_code_type_ug))}
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-6 mb-2">
                {renderFormControl(filters, 'id_code_ug', translate('common:Code'), codes
                  .filter(c => (filters.id_code_type_ug === 0
                  || c.id_code_type_ug === filters.id_code_type_ug)
                  && (filters.id_code_group_ug === 0
                    || c.id_code_group_ug === filters.id_code_group_ug)))}
              </div>
              <div className="col-6 mb-2">
                {renderFormControl(filters, 'id_unitary_operation_group', translate('common:UnitOperationGroup'), unitOperationGroups)}
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-6">
                {renderFormControl(filters, 'id_unitary_operation', translate('common:UnitOperation'), unitOperations
                  .filter(c => filters.id_unitary_operation_group === 0
                  || c.id_unitary_operation_group === filters.id_unitary_operation_group))}
              </div>
              <div className="col-6">
                {renderFormControl(
                  filters, 'id_element_point', translate('common:SubElement'), filteredSubElements,
                )}
                {renderCheckbox('show_all', translate('common:ShowAll'))}
              </div>
            </div>
            <div className="d-flex mb-2">
              <PF2MCheckboxSelect
                classes={{
                  field: classes.field,
                }}
                inputLabel={translate('common:Element')}
                options={elements}
                onChange={e => handleChange('id_element', e.target.value)}
                checkboxs={
                  [
                    { field: 'is_origin', label: translate('common:Origins') },
                    { field: 'is_destination', label: translate('common:Destinations') },
                  ]
                }
                filter={filterElementCheckBox}
                map={e => ({ value: e.id, label: e.name })}
                selectedValue={filters.id_element}
                initialState={{ is_origin: false, is_destination: false }}
                headOptions={[{ value: 0, label: translate('common:All') }]}
              />
            </div>
            <div className="d-flex justify-content-end">
              <CleanFilters
                label={translate('common:ClearFilters')}
                onClick={restoreFilters}
                className={classes.icon}
              />
              <PF2MSearchButton
                onClick={() => {
                  onSubmit();
                  closeModal();
                }}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
});

OperationControlFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(withModal(OperationControlFilter));
