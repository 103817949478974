import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

import { useTranslation } from 'react-i18next';
import {
  getOperatorsTemplate,
  exportOperators,
  importOperators,
  getOperators,
  resetFileUploadErrors,
} from '~/store/manager/actions';

const OperatorsTab = ({
  classes,
  operators,
  getOperators: getAll,
  getOperatorsTemplate: getTemplateAction,
  exportOperators: exportAction,
  importOperators: importAction,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [operatorsLoaded, setOperatorsLoaded] = useState(false);
  const [showDialogImport, setShowDialogImport] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!operatorsLoaded) {
      fetchOperators();
      setOperatorsLoaded(true);
    }
  }, [operatorsLoaded, getAll]);

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
    }
  }, [fileUploadErrors]);

  const columns = [
    { field: 'id', title: translate('common:Registration') },
    {
      field: 'name',
      title: translate('common:Name'),
      width: '100%',
      wrap: true,
    },
    { field: 'type_name', title: translate('common:Type') },
    { field: 'password', title: translate('common:Password') },
    { field: 'active_name', title: 'STATUS' },
    { field: 'group_name', title: translate('common:Group') },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={operators}
        columns={columns}
      />
      <Footer />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
    </div>
  );
};

OperatorsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  operators: PropTypes.array,
  getOperators: PropTypes.func,
  getOperatorsTemplate: PropTypes.func,
  exportOperators: PropTypes.func,
  importOperators: PropTypes.func,
};

OperatorsTab.defaultProps = {
  operators: [],
  getOperators: () => {},
  getOperatorsTemplate: () => {},
  exportOperators: () => {},
  importOperators: () => {},
};

const mapStateToProps = state => ({
  operators: state.manager.operators,
});

const mapDispatchToProps = {
  getOperators,
  getOperatorsTemplate,
  exportOperators,
  importOperators,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OperatorsTab));
