import React, { useState, useEffect } from 'react';
import {
  withStyles, Fab, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { parseDateToTimestamp } from '~/utils/moment';
import PF2MButton from '~/components/PF2MButton';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import PF2MTable from '~/components/PF2MTable';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import usePlatformConfiguration from '~/services/platformConfiguration';
import {
  removeAnomalyLog,
  updateAnomalyLogs,
  setTransportAnomalyListValues,
  updateTransportAnomalyList,
  resetOriginalTransportAnomalyList,
  setLoadAnomalyListValues,
  updateLoadAnomalyList,
  resetOriginalLoadAnomalyList,
} from '~/store/dispatch/actions';

const AnomalyLogsEditModal = ({
  classes, handleClose, data, anomalousTable,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [dataReport, setDataReport] = useState([]);
  const [columnsReport, setColumnsReport] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const materials = useSelector(state => state.nref.materials);
  const transportList = useSelector(state => state.dispatch.transportAnomalyList);
  const loadList = useSelector(state => state.dispatch.loadAnomalyList);
  const codeList = useSelector(state => state.dispatch.anomalyLogsCodes);
  const cloudList = useSelector(state => state.dispatch.anomalyLogsCloud);
  const currentFilter = useSelector(state => state.dispatch.currentAnomalyLogsFilter);
  const anomalyLogs = useSelector(state => state.dispatch.anomalyLogs);
  const isAnomalyLogsDirty = useSelector(state => state.dispatch.isAnomalyLogsDirty);
  const isTransportListDirty = useSelector(state => state.dispatch.isTransportAnomalyListDirty);
  const isLoadListDirty = useSelector(state => state.dispatch.isLoadAnomalyListDirty);

  const clientReportUrl = usePlatformConfiguration('url_reports');

  const redirecToReport = (row) => {
    const startTimestamp = parseDateToTimestamp(row.start_date, row.start_time);
    const endTimestamp = parseDateToTimestamp(row.end_date, row.end_time);
    const equipId = row.id_equipment;

    const paramsObj = {
      data_inicial: startTimestamp,
      data_final: endTimestamp,
      equip_ids: equipId,
    };

    const searchParams = new URLSearchParams(paramsObj);

    if (clientReportUrl.value === '') {
      setIsModalOpen(true);
      return;
    }

    const reportUrl = new URL(clientReportUrl.value);

    reportUrl.search = searchParams;

    window.open(reportUrl, '_blank');
  };

  const columnsCloudReport = [
    {
      field: 'cnt',
      title: translate('common:Occurrences'),
    },
    {
      field: 'point_name',
      title: translate('common:Polygon'),
    },
    {
      field: 'operator_name',
      title: translate('common:Operator'),
    },
  ];

  const columnsCodeReport = [
    {
      field: 'duration',
      title: translate('common:Duration'),
      type: 'number',
    },
    {
      field: 'point_name',
      title: translate('common:Polygon'),
    },
    {
      field: 'operator_name',
      title: translate('common:Operator'),
    },
  ];
  const columnsTransportReport = [
    {
      field: 'start_date',
      title: translate('common:StartDate'),
      editable: true,
      updateOnChange: true,
      width: '150px',
      datePicker: true,
    },
    {
      field: 'end_date',
      title: translate('common:EndDate'),
      editable: true,
      updateOnChange: true,
      width: '150px',
      datePicker: true,
    },
    {
      field: 'id_mine',
      selectItems: elements,
      editable: true,
      width: 200,
      title: translate('common:Origin'),
    },
    {
      field: 'id_mine_origin',
      selectItems: subElements,
      editable: true,
      filterValue: 'id_mine',
      filterKey: 'id_element',
      width: 200,
      title: translate('common:OriginPoint'),
    },
    {
      field: 'id_destination',
      selectItems: elements,
      editable: true,
      width: 200,
      title: translate('common:Destination'),
    },
    {
      field: 'id_destination_point',
      selectItems: subElements,
      editable: true,
      filterValue: 'id_destination',
      filterKey: 'id_element',
      width: 200,
      title: translate('common:DestinationPoint'),
    },
    {
      field: 'id_material_truck',
      selectItems: materials,
      editable: true,
      width: 200,
      title: translate('common:Material'),
    },
  ];

  const columnsLoadReport = [
    {
      field: 'start_date',
      title: translate('common:StartDate'),
      editable: true,
      updateOnChange: true,
      width: '150px',
      datePicker: true,
    },
    {
      field: 'end_date',
      title: translate('common:EndDate'),
      editable: true,
      updateOnChange: true,
      width: '150px',
      datePicker: true,
    },
    {
      field: 'id_mine',
      selectItems: elements,
      editable: true,
      width: 200,
      title: translate('common:Origin'),
    },
    {
      field: 'id_mine_origin',
      selectItems: subElements,
      filterValue: 'id_mine',
      filterKey: 'id_element',
      editable: true,
      width: 200,
      title: translate('common:OriginPoint'),
    },
    {
      field: 'id_destination_esc',
      selectItems: elements,
      editable: true,
      width: 200,
      title: translate('common:Destination'),
    },
    {
      field: 'id_destination_point_esc',
      selectItems: subElements,
      editable: true,
      filterValue: 'id_destination_esc',
      filterKey: 'id_element',
      width: 200,
      title: translate('common:DestinationPoint'),
    },
    {
      field: 'id_material_esc',
      selectItems: materials,
      editable: true,
      width: 200,
      title: translate('common:Material'),
    },
  ];

  useEffect(() => {
    let reportList = [];
    switch (anomalousTable) {
      case 1:
        reportList = transportList;
        break;
      case 2:
        reportList = loadList;
        break;
      case 3:
        reportList = codeList;
        break;
      case 4:
        reportList = cloudList;
        break;
      default:
        reportList = [];
    }
    const newDataReport = [1, 2, 3].includes(anomalousTable)
      ? reportList.filter(i => data.some(k => k.uid === i.uid))
      : reportList.filter(i => i.id_equip === data[0].id_equipment);
    // <== temporário, o certo seria: k.anomalous_table_report_id === i.id
    // mas não temos o valor de anomalous_table_report_id
    if (JSON.stringify(dataReport) !== JSON.stringify(newDataReport)) {
      setDataReport(newDataReport);
      switch (anomalousTable) {
        case 1:
          setColumnsReport(columnsTransportReport);
          break;
        case 2:
          setColumnsReport(columnsLoadReport);
          break;
        case 3:
          setColumnsReport(columnsCodeReport);
          break;
        case 4:
          setColumnsReport(columnsCloudReport);
          break;
        default:
          reportList = [];
      }
    }
  },
  [
    dataReport,
    transportList,
    loadList,
    codeList,
    cloudList,
    anomalousTable,
    data,
    columnsTransportReport,
    columnsLoadReport,
    columnsCodeReport,
    columnsCloudReport,
  ]);

  const isSomethingDirty = (isTransportListDirty || isLoadListDirty || isAnomalyLogsDirty);

  const setReportValues = (row, field, value) => {
    switch (anomalousTable) {
      case 1:
        return dispatch(setTransportAnomalyListValues(row, field, value));
      case 2:
        return dispatch(setLoadAnomalyListValues(row, field, value));
      default:
    }
    return null;
  };

  const updateReportList = (columns, filter) => {
    switch (anomalousTable) {
      case 1:
        return dispatch(updateTransportAnomalyList(columns, filter));
      case 2:
        return dispatch(updateLoadAnomalyList(columns, filter));
      default:
    }
    return null;
  };

  const restoreOriginalReport = () => {
    switch (anomalousTable) {
      case 1:
        return dispatch(resetOriginalTransportAnomalyList());
      case 2:
        return dispatch(resetOriginalLoadAnomalyList());
      default:
    }
    return null;
  };

  const columns = [
    {
      field: 'date',
      title: translate('common:Date'),
    },
    {
      field: 'time',
      title: translate('common:Time'),
    },
    {
      field: 'anomaly_type_name',
      title: translate('common:AnomalyType'),
    },
  ];

  const renderHeader = () => (
    <div>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
      >
        {`${translate('common:AnomalyLogs')}`}
        <br />
        {`${translate('common:EquipmentGroup')}: ${data[0].equipment_groups_name} | `}
        {`${translate('common:EquipmentType')}: ${data[0].equipment_type_name} | `}
        {`${translate('common:Equipment')}: ${data[0].group_equipament_links_name}`}
      </Typography>
    </div>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          {([1, 2].includes(anomalousTable)) && (
            <PF2MTable
              customStyles={{ height: '250px' }}
              data={data}
              columns={columns}
              removeItem={(row) => {
                dispatch(removeAnomalyLog(row));
              }}
            />
          )}
          { ([1].includes(anomalousTable))
            ? (
              <PF2MTable
                customStyles={{ height: '250px' }}
                data={dataReport}
                columns={columnsReport}
                updateItem={(row, field, value) => {
                  setReportValues(row, field, value);
                }}
                customItem={(row) => {
                  redirecToReport(row);
                }}
              />
            )
            : (
              <PF2MTable
                customStyles={{ height: '250px' }}
                data={dataReport}
                columns={columnsReport}
                updateItem={(row, field, value) => {
                  setReportValues(row, field, value);
                }}
              />
            )
          }
          <div style={{
            display: 'flex',
            marginTop: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            {([1, 2].includes(anomalousTable)) && (
              <>
                <PF2MButton
                  color="primary"
                  onClick={() => {
                    restoreOriginalReport();
                    handleClose(false);
                  }}
                  disabled={!isSomethingDirty}
                >
                  <DeleteOutline className={classes.icon} />
                  {translate('common:DiscardChanges')}
                </PF2MButton>
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  disabled={!isSomethingDirty}
                  onClick={() => {
                    updateReportList(dataReport, currentFilter);
                    dispatch(updateAnomalyLogs(anomalyLogs, currentFilter));
                    handleClose(false);
                  }}
                  className={classes.saveButton}
                >
                  {translate('common:SaveData')}
                </Fab>
              </>
            )}
            {!([1, 2].includes(anomalousTable)) && (
              <PF2MButton
                color="primary"
                onClick={() => { handleClose(false); }}
              >
                {translate('common:Ok')}
              </PF2MButton>
            )}
          </div>

        </CardBody>
      </Card>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:ReportUrlNotRegistered')}
        open={isModalOpen}
        onConfirm={() => setIsModalOpen(false)}
      />
    </div>
  );
};

AnomalyLogsEditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  data: PropTypes.array,
  anomalousTable: PropTypes.number,
};

AnomalyLogsEditModal.defaultProps = {
  handleClose: null,
  data: [],
  anomalousTable: 1,
};

export default withStyles(styles)(AnomalyLogsEditModal);
